import { FC } from 'react'

type CloseIconProps = {
  className?: string
}

export const CloseIcon: FC<CloseIconProps> = ({ className }) => {
  return (
    <svg
      className={className}
      width="1em"
      height="1em"
      viewBox="0 0 22 22"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M11.407 12.4145L20.4927 21.5002L21.9069 20.0859L12.8212 11.0002L21.9069 1.91455L20.4927 0.500337L11.407 9.58603L1.90689 0.0859375L0.492676 1.50015L9.99277 11.0002L0.492676 20.5003L1.90689 21.9146L11.407 12.4145Z"
        fill="#131313"
      />
    </svg>
  )
}
