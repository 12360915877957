'use client'

import { FC } from 'react'
import ReactModal from 'react-modal'
import { Overlay } from './Overlay'
import { Content } from './Content'
import {
  overlayAfterOpenClass,
  overlayBaseClass,
  overlayBeforeCloseClass,
} from './Overlay.css'
import {
  contentAfterOpenClass,
  contentBaseClass,
  contentBeforeCloseClass,
} from './Content.css'

export const MODAL_OPEN_TIME = 400
export const MODAL_CLOSE_TIME = 200

export type ModalOverlayStyle = 'transparent' | 'blur' | 'light' | 'dark'

export type ModalContentPosition =
  | 'center'
  | 'bottom'
  | 'left'
  | 'right'
  | 'top'
export type ModalContentVariant = 'default' | 'white-rounded' | 'rounded'
export type ModalContentSize =
  | 'intrinsic'
  | 'stretch'
  | 'stretch-x'
  | 'stretch-y'
  | 'stretch-full'

export type ModalTransition =
  | 'fade'
  | 'slide-from-right'
  | 'slide-from-top'
  | 'slide-from-left'
  | 'slide-from-bottom'

export type ModalProps = ReactModal.Props & {
  // Content
  variant?: ModalContentVariant
  position?: ModalContentPosition
  size?: ModalContentSize
  // Overlay
  overlayCloseButton?: boolean
  overlayCloseButtonLabel?: string
  overlayVariant?: ModalOverlayStyle
  // Animations
  transition?: ModalTransition

  // General props - from 'react-modal'
  //
  // isOpen: boolean
  // onBeforeOpen: () => void
  // onAfterOpen: () => void
  // onBeforeClose: () => void
  // onAfterClose: () => void
  // preventScroll: boolean
  // onRequestClose: () => void
}

export const Modal: FC<ModalProps> = ({
  variant = 'default',
  position = 'center',
  size = 'intrinsic',
  overlayCloseButton,
  overlayCloseButtonLabel,
  overlayVariant = 'dark',
  transition = 'fade',
  isOpen,
  onRequestClose,
  children,
  ...reactModalProps
}) => {
  const openTime = MODAL_OPEN_TIME
  const closeTime = MODAL_CLOSE_TIME

  const OVERLAY_CLASSES = {
    base: overlayBaseClass({
      hasPadding: position === 'center' && size === 'intrinsic',
    }),
    afterOpen: overlayAfterOpenClass,
    beforeClose: overlayBeforeCloseClass,
  }

  const CONTENT_CLASSES = {
    base: contentBaseClass({ variant, position, size, transition }),
    afterOpen: contentAfterOpenClass({ transition }),
    beforeClose: contentBeforeCloseClass({ transition }),
  }

  const contentProps = {
    variant,
    position,
    size,
    transition,
    closeTime,
    openTime,
    onRequestClose,
  }
  const overlayProps = {
    overlayCloseButton,
    overlayCloseButtonLabel,
    overlayVariant,
    closeTime,
    openTime,
  }

  const appElement = typeof document !== 'undefined' ? document.body : undefined

  return (
    <ReactModal
      {...reactModalProps}
      isOpen={isOpen}
      appElement={appElement}
      onRequestClose={onRequestClose}
      overlayElement={(props, contentElement) => (
        <Overlay {...props} {...overlayProps}>
          {contentElement}
        </Overlay>
      )}
      contentElement={(props, children) => (
        <Content {...props} {...contentProps}>
          {children}
        </Content>
      )}
      className={CONTENT_CLASSES}
      overlayClassName={OVERLAY_CLASSES}
      closeTimeoutMS={closeTime}
    >
      {children}
    </ReactModal>
  )
}
