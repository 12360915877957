import { LinkField, PortableTextBlocks, SectionTheme } from '@pienso/models'
import {
  PortableText,
  PortableTextComponents,
  PortableTextMarkComponentProps,
} from '@portabletext/react'
import { RecipeVariants } from '@vanilla-extract/recipes'
import { PropsWithChildren } from 'react'
import { Link } from '../Clickable/Link/Link'
import { type clickable } from '../Clickable/clickable.css'
import {
  heading,
  list,
  listItem,
  mark,
  paragraph,
  unorderedList,
} from './RichText.css'

interface RichTextProps {
  value?: PortableTextBlocks
  sectionTheme?: SectionTheme
}

type RichTextAppearance = Extract<
  NonNullable<NonNullable<RecipeVariants<typeof clickable>>['appearance']>,
  `richText${string}`
>

const SECTION_BACKGROUND_COLOR_TO_RICH_TEXT_LINK_APPEARANCE: Record<
  SectionTheme['backgroundColor'],
  RichTextAppearance
> = {
  blue: 'richTextLinkBlack',
  gray: 'richTextLinkBlack',
  green: 'richTextLinkBlack',
  lightestGray: 'richTextLinkBlue',
  lightGray: 'richTextLinkBlue',
  white: 'richTextLinkBlue',
  red: 'richTextLinkBlack',
}

function getRichTextLinkAppearance(
  sectionTheme?: SectionTheme,
): RichTextAppearance {
  if (!sectionTheme) {
    return 'richTextLinkBlue'
  }

  return SECTION_BACKGROUND_COLOR_TO_RICH_TEXT_LINK_APPEARANCE[
    sectionTheme.backgroundColor
  ]
}

export const LinkMark = ({
  value,
  children,
  sectionTheme,
}: PropsWithChildren<
  PortableTextMarkComponentProps<LinkField & { _type: string }> & {
    sectionTheme?: SectionTheme
  }
>) => {
  const richTextLinkAppearance = getRichTextLinkAppearance(sectionTheme)

  return (
    <Link {...value?.payload} appearance={richTextLinkAppearance}>
      {children}
    </Link>
  )
}
export const RichText = ({ value, sectionTheme }: RichTextProps) => {
  const components: PortableTextComponents = {
    marks: {
      link: ({ value, children, ...restProps }) => (
        <LinkMark {...restProps} value={value} sectionTheme={sectionTheme}>
          {children}
        </LinkMark>
      ),
      em: ({ children }) => (
        <em className={mark({ type: 'em' })}>{children}</em>
      ),
      underline: ({ children }) => (
        <span className={mark({ type: 'underline' })}>{children}</span>
      ),
      strong: ({ children }) => (
        <strong className={mark({ type: 'strong' })}>{children}</strong>
      ),
      textColor: ({ children, value }) => {
        return <span style={{ color: value.hex }}>{children}</span>
      },
      textHighlight: ({ children, value }) => {
        return <span style={{ background: value.hex }}>{children}</span>
      },
    },
    block: {
      normal: ({ children }) => <p className={paragraph}>{children}</p>,
      h2: ({ children }) => (
        <h2 className={heading({ level: 2 })}>{children}</h2>
      ),
    },
    list: {
      bullet: ({ children }) => <ul className={unorderedList}>{children}</ul>,
      number: ({ children }) => <ol className={list}>{children}</ol>,
    },
    listItem: {
      bullet: ({ children }) => <li className={listItem}>{children}</li>,
    },
  }

  return value ? <PortableText value={value} components={components} /> : null
}
