import "../../../../../node_modules/@vanilla-extract/webpack-plugin/vanilla.virtual.css?%7B%22fileName%22%3A%22..%2F..%2Flibs%2Fstyles%2Fsrc%2Flib%2Ftheme.css.ts.vanilla.css%22%2C%22source%22%3A%22%23H4sIAAAAAAAAA22USW5jMQxE9zlFAVoH%2BOIfVVnZsXONhqc4cTw7U6fRd29Qq6b4VbsHqUiRlHg9nd7x5w64v%2F%2FVHw%2B7%2FloRIU5UD%2F%2FjSATpVAYLEeq8DK6J0FQqgxvFM5XBLRHapDK4I0KXl8E9EfpaZfBAhKFSGZwUP6oMXhAh5WXwkgiTTmXwigjTWmXwWvGTyuANER7zMviZCLOkMnhLhHmnMviFCE%2BiMviVCJVMp100eJfxpG97g98yTrGxmewVV9M0s96HjCcyHQw%2B5rzn%2Fdze8pQTjENlQ551fGqVwZfxy1%2FVZFAZfFOcl8HvhL32BxFtuz8JseSLKJr8rafspt%2BKWoN%2B1MnuihVRlywSwzDY%2FKMQ1fnbspqQkjVEU7KW6ErWEUPJes25hAMRXZRERBdmQUQXZ0lEF2illSjhmhAXaEOIC%2FRMiPPcaiVL%2BELUzvOVqF2eO6Jxx9%2BIxgXaE63zPBCt8zwSnfM8EZ270ZnoneeFGNzxK5FcoFuev5LqcPsy64T7kn4qdVf90jY7B532xjnowPu6%2FOhMlA5SKS2jSdRRcXuFiKn0FR19l5k0OhjOtyUkub0dUfdub080bohlyC%2FXWaSxoslipOuyJNqyZLIi6qYu6XqsmbIhuljCsYcgW8J1R15G3pG8jsHdyOuQN%2F%2BryN7%2FIHIY%2BQLkOBbmpJ9oSiU%2BE23JLiPBrzptJbzlz%2FRwe7j7%2Bw8BJQpvhwgAAA%3D%3D%22%7D"
import "../../../../../node_modules/@vanilla-extract/webpack-plugin/vanilla.virtual.css?%7B%22fileName%22%3A%22..%2F..%2Flibs%2Fstyles%2Fsrc%2Flib%2Ftypography.css.ts.vanilla.css%22%2C%22source%22%3A%22%23H4sIAAAAAAAAA63Uz26CMBzA8TtP0aMe2vQPiOJlrwKjQI1UhgSFhXdfZBrtftUU5rX5pp%2B2lJIqU13yRdG3h1B20A0%2BSZUXTYRCSrfe4JHfgMEgMAIOA98IxD3I4lLtuwi1cb3AeBwqZJwqnS%2B3t%2Baoehkhf12dL0N7pSUurvNSshnHZNPIGh%2Br%2BFPpPEKYEipk%2BUCGs0g6jWSBYSbvNANHNJuDCmFBmaO4myNy29dkhD7dJjdMPct03uXjZa1fUskh7f46bPOvvbXTwZUVFNwN7KeDggRW8umdMUX2%2BgWwk1bQcYvs9f9vBdks8KOUqYrRolQan1TaFJelU1qdl%2BMKjBfQ9OgVHO5ZCLNwBarEqcpgFYSg2sHKF6DSsBIcVDWsuA%2Bq1nIUa1D1lgrOxWznasks58roLRt%2BAIdlYAUJBwAA%22%7D"
import "../../../../../node_modules/@vanilla-extract/webpack-plugin/vanilla.virtual.css?%7B%22fileName%22%3A%22..%2F..%2Flibs%2Fblog%2Fsrc%2Flib%2FBlogArticleRichText%2FBlogArticleRichText.css.ts.vanilla.css%22%2C%22source%22%3A%22%23H4sIAAAAAAAAA5WT0WrCMBSG7%2FsU52agg5S0CroIY28iMUnbs6VJjcdZGb77sOpcqlN3Gfi%2Bk5%2FD%2BdN5xvP1tjUj%2BEoAGDu9uYBPGQaMzSeufp%2BEbDqcRUDWBz56QC5ASasG%2BXOf68BatmyDmioBU86bdpbskvSc5hVEgWFFTFVodReulqFEx8g3P1%2BfsgwvbSuvyQtP5Ou%2Bn8f%2B%2BIHv7kzk8cTpjQzHtWBsvNw3TGzI%2B4aMDdUZhXfEVrS1RgCStKgi6LA%2BMi0xbZQPktA7AWunTbDoTASb88SNwbIiAQtvdcQUHaO89aGXbxzHKx9bAUAjtUZXMmsKur4ki%2FCfsb0gVWcsfNAmCMibFlbeoo4l%2B%2BsoLg8G5Jr8lRsBOHYg4%2Fxp%2F%2BlbbTRKGNToTvXIRvt%2BDLsQadzXBxp7s7PLC%2BSv1i4Prd0lu28UvtnxMwQAAA%3D%3D%22%7D"
import { createRuntimeFn as _7a468 } from '@vanilla-extract/recipes/createRuntimeFn';
export var blogMarginBlockBottom = 'var(--_102uyxe0)';
export var blogMarginBlockTop = 'var(--_102uyxe1)';
export var blogMarginEnd = 'var(--_102uyxe2)';
export var divider = '_102uyxeh';
export var heading = _7a468({defaultClassName:'_102uyxe4',variantClassNames:{level:{'2':'pfiybqr pfiybq0','5':'pfiybqn pfiybq0'}},defaultVariants:{},compoundVariants:[]});
export var label = '_102uyxea pfiybq17 pfiybq1';
export var list = '_102uyxeg pfiybqv';
export var mark = _7a468({defaultClassName:'_102uyxeb',variantClassNames:{type:{em:'_102uyxec',underline:'_102uyxed',strong:'_102uyxee','attention-text':'_102uyxef pfiybqv'}},defaultVariants:{},compoundVariants:[]});
export var paragraph = _7a468({defaultClassName:'pfiybqv pfiybq2',variantClassNames:{withMargin:{true:'_102uyxe8'}},defaultVariants:{},compoundVariants:[]});
export var root = '_102uyxe3';
export var small = '_102uyxe9 pfiybq13 pfiybq1';