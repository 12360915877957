'use client'

import {
  Disclosure,
  DisclosureItemProps,
  DisclosuresList,
  RichText,
} from '@pienso/components'
import * as s from './SectionAccordion.css'
import { type SectionAccordionProps } from './SectionAccordion.model'
import { SectionTheme } from '@pienso/models'

function Accordion({
  items,
  headingLevel,
  sectionTheme,
}: {
  items: SectionAccordionProps['items']
  headingLevel: DisclosureItemProps['headingLevel']
  sectionTheme?: SectionTheme
}) {
  return (
    <div className={s.accordion}>
      <DisclosuresList>
        {items?.map((item) => {
          return (
            <Disclosure
              key={item._key}
              itemId={item._key}
              label={item.term}
              headingLevel={headingLevel}
            >
              <div className={s.accordionContent}>
                <RichText
                  value={item.description}
                  sectionTheme={sectionTheme}
                />
              </div>
            </Disclosure>
          )
        })}
      </DisclosuresList>
    </div>
  )
}

export { Accordion }
