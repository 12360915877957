import "../../../../../node_modules/@vanilla-extract/webpack-plugin/vanilla.virtual.css?%7B%22fileName%22%3A%22..%2F..%2Flibs%2Fstyles%2Fsrc%2Flib%2Ftheme.css.ts.vanilla.css%22%2C%22source%22%3A%22%23H4sIAAAAAAAAA22USW5jMQxE9zlFAVoH%2BOIfVVnZsXONhqc4cTw7U6fRd29Qq6b4VbsHqUiRlHg9nd7x5w64v%2F%2FVHw%2B7%2FloRIU5UD%2F%2FjSATpVAYLEeq8DK6J0FQqgxvFM5XBLRHapDK4I0KXl8E9EfpaZfBAhKFSGZwUP6oMXhAh5WXwkgiTTmXwigjTWmXwWvGTyuANER7zMviZCLOkMnhLhHmnMviFCE%2BiMviVCJVMp100eJfxpG97g98yTrGxmewVV9M0s96HjCcyHQw%2B5rzn%2Fdze8pQTjENlQ551fGqVwZfxy1%2FVZFAZfFOcl8HvhL32BxFtuz8JseSLKJr8rafspt%2BKWoN%2B1MnuihVRlywSwzDY%2FKMQ1fnbspqQkjVEU7KW6ErWEUPJes25hAMRXZRERBdmQUQXZ0lEF2illSjhmhAXaEOIC%2FRMiPPcaiVL%2BELUzvOVqF2eO6Jxx9%2BIxgXaE63zPBCt8zwSnfM8EZ270ZnoneeFGNzxK5FcoFuev5LqcPsy64T7kn4qdVf90jY7B532xjnowPu6%2FOhMlA5SKS2jSdRRcXuFiKn0FR19l5k0OhjOtyUkub0dUfdub080bohlyC%2FXWaSxoslipOuyJNqyZLIi6qYu6XqsmbIhuljCsYcgW8J1R15G3pG8jsHdyOuQN%2F%2BryN7%2FIHIY%2BQLkOBbmpJ9oSiU%2BE23JLiPBrzptJbzlz%2FRwe7j7%2Bw8BJQpvhwgAAA%3D%3D%22%7D"
import "../../../../../node_modules/@vanilla-extract/webpack-plugin/vanilla.virtual.css?%7B%22fileName%22%3A%22..%2F..%2Flibs%2Fstyles%2Fsrc%2Flib%2Ftypography.css.ts.vanilla.css%22%2C%22source%22%3A%22%23H4sIAAAAAAAAA63Uz26CMBzA8TtP0aMe2vQPiOJlrwKjQI1UhgSFhXdfZBrtftUU5rX5pp%2B2lJIqU13yRdG3h1B20A0%2BSZUXTYRCSrfe4JHfgMEgMAIOA98IxD3I4lLtuwi1cb3AeBwqZJwqnS%2B3t%2Baoehkhf12dL0N7pSUurvNSshnHZNPIGh%2Br%2BFPpPEKYEipk%2BUCGs0g6jWSBYSbvNANHNJuDCmFBmaO4myNy29dkhD7dJjdMPct03uXjZa1fUskh7f46bPOvvbXTwZUVFNwN7KeDggRW8umdMUX2%2BgWwk1bQcYvs9f9vBdks8KOUqYrRolQan1TaFJelU1qdl%2BMKjBfQ9OgVHO5ZCLNwBarEqcpgFYSg2sHKF6DSsBIcVDWsuA%2Bq1nIUa1D1lgrOxWznasks58roLRt%2BAIdlYAUJBwAA%22%7D"
import "../../../../../node_modules/@vanilla-extract/webpack-plugin/vanilla.virtual.css?%7B%22fileName%22%3A%22..%2F..%2Flibs%2Fsections%2Fsection-double-content%2Fsrc%2Flib%2FSectionDoubleContent.css.ts.vanilla.css%22%2C%22source%22%3A%22%23H4sIAAAAAAAAA5WS326CMBTG732KcymJVYrOuu5mb7J0ULCutE0pwrb47gsFFQHjvGpy%2Bjvfd%2F4t6%2BgYfuEQfmcAiSiMZN8UMiuStxn4FzmeG8kcR7GWZa4KCji141%2Brq%2FPXabZsZbGXzYVCey6yvaNAtqGpe0TkCa9kdUUBX4RbNx%2B50Oun6I2nDUsSoTIKR2bnCH0QlR%2BIjUQA4SB0CHrJ26cmYrnhzM03C5za4MKx0mmUyqZSq6smbHWFMmYGxeDDNefa1w8SKuH1gC092jWFPrVzOqcQMxnPbzVtAGjUYL9DMt3hnRJj78ukyBQquEwpFI5ZN14ArOCl57LzLrGW2g4kTfAw%2BdUnV3vhOCoMizkFYzmSQvEexe6satzE1ltWInF7CjmrUayV48o1Yu85TwSDeXOtHUE2oakDr35zFY%2FOAEcLOB%2FC%2BBRKVXDXfrWJU%2BPmXfLjRUfrO4sGOF0LJ%2F3Cz%2BMmsAK8HpC7KbJZDGnByUnhMByO6r4j%2Fq%2FjtnP8A2apyYmlBAAA%22%7D"
import { createRuntimeFn as _7a468 } from '@vanilla-extract/recipes/createRuntimeFn';
export var content = 'x2v0k16';
export var contentWrap = _7a468({defaultClassName:'x2v0k13',variantClassNames:{verticalPadding:{true:'x2v0k14',false:'x2v0k15'}},defaultVariants:{},compoundVariants:[]});
export var description = 'x2v0k19 pfiybqr pfiybq1';
export var heading = 'x2v0k18 pfiybq3 pfiybq1';
export var links = 'x2v0k1a';
export var mediaWrap = 'x2v0k12';
export var textContent = 'x2v0k17';
export var wrap = _7a468({defaultClassName:'x2v0k10',variantClassNames:{hasMinHeight:{true:'x2v0k11'}},defaultVariants:{},compoundVariants:[]});