'use client'

import React, { ComponentPropsWithRef } from 'react'
import { ModalOverlayStyle } from './Modal'
import {
  backdrop,
  backdropTransitionCloseTime,
  backdropTransitionOpenTime,
} from './Overlay.css'
import { assignInlineVars } from '@vanilla-extract/dynamic'

export type OverlayProps = Omit<ComponentPropsWithRef<'div'>, 'className'> & {
  overlayCloseButton?: boolean
  overlayCloseButtonLabel?: string
  overlayVariant?: ModalOverlayStyle
  hasPadding?: boolean
  openTime: number
  closeTime: number
}

export const Overlay = React.forwardRef<HTMLDivElement, OverlayProps>(
  (
    {
      overlayCloseButton,
      overlayCloseButtonLabel,
      openTime,
      closeTime,
      children,
      hasPadding,
      overlayVariant,
      ...restProps
    },
    ref,
  ) => {
    return (
      <div
        {...restProps}
        ref={ref}
        style={assignInlineVars({
          [backdropTransitionOpenTime]: `${openTime}ms`,
          [backdropTransitionCloseTime]: `${closeTime}ms`,
        })}
      >
        <div
          className={backdrop({
            overlayVariant,
          })}
        />
        {children}
      </div>
    )
  },
)
Overlay.displayName = 'Overlay'
