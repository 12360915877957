'use client'

import { useEffect, useRef } from 'react'
import * as s from './CookiebotDeclaration.css'

function CookiebotDeclaration() {
  const cookieBotWrapperNode = useRef<HTMLDivElement>(null)

  useEffect(() => {
    if (cookieBotWrapperNode.current) {
      const script = document.createElement('script')
      script.id = 'CookieDeclaration'
      script.type = 'text/javascript'
      script.async = true
      script.src = `https://consent.cookiebot.com/10b1227a-f8df-4572-ae20-308aaf98da91/cd.js`

      cookieBotWrapperNode.current.appendChild(script)
    }
  }, [])

  return <div ref={cookieBotWrapperNode} className={s.declaration} />
}

export { CookiebotDeclaration }
