'use client'
import { FC, useEffect, useRef } from 'react'
import * as s from './SectionSlider.css'
import * as headerStyles from './SliderHeader.css'
import * as contentStyles from './SliderContent.css'
import { SectionSliderProps } from './SectionSlider.model'
import { useInView } from 'react-intersection-observer'
import { useStickyContent } from '@pienso/sticky-content-provider'
import { assignInlineVars } from '@vanilla-extract/dynamic'
import { ContentContainer, Media, SectionContainer } from '@pienso/components'
import { screenSizes, theme } from '@pienso/styles'
import {
  DESKTOP_SLIDE_GAP_VW,
  DESKTOP_SLIDES_PADDING_PX,
  MOBILE_SLIDE_GAP_VW,
  MOBILE_SLIDES_PADDING_PX,
} from './SectionSlider.css'

/**
 * Calculate the current scroll percentage of the current item in view
 * based on the overall scroll percentage
 */
function calculateCurrentItemScrollPercentage(
  allItemsScrollPercentage: number,
  currentItemInView: number,
  totalItems: number,
) {
  // Calculate the percentage height each item occupies
  const itemHeightPercent = 100 / totalItems

  // Calculate the start scroll percentage of the current item
  const currentItemStartPercent = (currentItemInView - 1) * itemHeightPercent

  // Calculate the scroll percentage within the current item
  const currentItemScrollPercentage =
    ((allItemsScrollPercentage - currentItemStartPercent) / itemHeightPercent) *
    100

  // Ensure the percentage is between 0 and 100 and handle edge cases
  if (currentItemScrollPercentage < 0) {
    return 0 // The view is above the current item
  } else if (currentItemScrollPercentage > 100) {
    return 100 // The view is below the current item
  } else {
    return currentItemScrollPercentage // The current visible percentage of the item in view
  }
}

function interpolate(percentage: number, start: number, end: number): number {
  return start + (end - start) * (percentage / 100)
}

type RGB = {
  r: number
  g: number
  b: number
}

function interpolateRGB(percentage: number, startRGB: RGB, endRGB: RGB): RGB {
  // Interpolate each RGB component
  const r = Math.round(
    startRGB.r + (endRGB.r - startRGB.r) * (percentage / 100),
  )
  const g = Math.round(
    startRGB.g + (endRGB.g - startRGB.g) * (percentage / 100),
  )
  const b = Math.round(
    startRGB.b + (endRGB.b - startRGB.b) * (percentage / 100),
  )

  return { r, g, b }
}

export const SectionSlider: FC<SectionSliderProps> = ({
  title,
  slides,
  priority,
}) => {
  // const refScrollContainer = useRef<HTMLDivElement | null>(null)
  const refFixedContainer = useRef<HTMLDivElement | null>(null)
  const scrollSlidesListRef = useRef<HTMLUListElement | null>(null)
  const rootSlidesListRef = useRef<HTMLUListElement | null>(null)
  const slidingCounterPartRef = useRef<HTMLUListElement | null>(null)
  const { disable, enable } = useStickyContent()

  const [refSectionContainer, isSectionInView] = useInView({
    threshold: 0,
    onChange: (inView) => {
      if (inView) {
        disable()
      } else {
        enable()
      }
    },
  })

  const handleScroll = () => {
    if (refFixedContainer.current && refFixedContainer.current) {
      const visibleSlidesList = Array.from(
        rootSlidesListRef.current?.children || [],
      ) as HTMLElement[]
      const slidingCounterPart = slidingCounterPartRef.current

      const windowY = window.scrollY
      const scrollSlidesAmount = slides?.length || 0
      const fixedContainerOffsetTop = refFixedContainer?.current?.offsetTop || 0
      const isSliderInView = windowY > fixedContainerOffsetTop
      const isSliderBelowTheFold =
        windowY >
        fixedContainerOffsetTop + refFixedContainer.current.clientHeight
      const isSliderAboveTheFold = windowY < fixedContainerOffsetTop

      // fix slides and counter  initial position (if the slider is below the fold)
      if (isSliderBelowTheFold) {
        visibleSlidesList.forEach((slide, index) => {
          requestAnimationFrame(() => {
            if (index === scrollSlidesAmount - 1) {
              return slide.style.transform === `translate3d(-5%, 0%, 0)`
            }
            if (slide.style.transform === `translate3d(-100%, 0%, 0)`) return
            slide.style.transform = `translate3d(-100%, 0%, 0)`
          })
        })

        if (
          slidingCounterPart &&
          slidingCounterPart.style.transform !== `translate3d(0px, -80%, 0px)`
        ) {
          slidingCounterPart.style.transform = `translate3d(0px, -80%, 0px)`
        }

        return
      }

      // fix slides and counter  initial position (if the slider is above the fold)
      if (isSliderAboveTheFold) {
        visibleSlidesList.forEach((slide) => {
          requestAnimationFrame(() => {
            if (slide.style.transform === `translate3d(0%, 0%, 0)`) return
            slide.style.transform = `translate3d(0%, 0%, 0)`
          })
        })
        if (
          slidingCounterPart &&
          slidingCounterPart.style.transform !== `translate3d(0px, 0%, 0px)`
        ) {
          slidingCounterPart.style.transform = `translate3d(0px, 0%, 0px)`
        }
        return
      }

      // animation
      if (isSliderInView) {
        const scrollPosition = windowY - fixedContainerOffsetTop
        const windowHeight = window.innerHeight
        const scrollHeight =
          refFixedContainer.current.scrollHeight - windowHeight
        const scrollPercentageExact = (scrollPosition / scrollHeight) * 100

        const currentSlideIndex = Math.floor(
          (scrollPosition / scrollHeight) * scrollSlidesAmount,
        )

        // counter animation
        if (slidingCounterPart) {
          const counterScrollPercent = Math.min(scrollPercentageExact, 80)
          requestAnimationFrame(() => {
            slidingCounterPart.style.transform = `translate3d(0px, -${counterScrollPercent}%, 0px)`
          })
        }

        // slides animation
        visibleSlidesList.forEach((currentSlide, index) => {
          const isLastSlide = index === visibleSlidesList.length - 1
          const isNextSlide = index > currentSlideIndex
          const isPrevSlide = index < currentSlideIndex

          // fix non-active slides position (skip the last slide as it has a different final position)
          if (isPrevSlide && !isLastSlide) {
            requestAnimationFrame(() => {
              if (currentSlide.style.transform === `translate3d(-100%, 0%, 0)`)
                return
              return (currentSlide.style.transform = `translate3d(-100%, 0%, 0)`)
            })
          }

          // fix non-active slides position
          if (isNextSlide) {
            requestAnimationFrame(() => {
              if (currentSlide.style.transform === `translate3d(0%, 0%, 0)`)
                return
              return (currentSlide.style.transform = `translate3d(0%, 0%, 0)`)
            })
          }

          // calculate the scroll percentage for the current slide based on the overall scroll percentage
          const slideScrollPercentage = calculateCurrentItemScrollPercentage(
            scrollPercentageExact,
            currentSlideIndex + 1,
            scrollSlidesAmount,
          )

          // animate the current visible slide and the next slide
          if (currentSlideIndex === index) {
            requestAnimationFrame(() => {
              /**
               * Main slide animation
               */
              if (isLastSlide) {
                // last slide has a different final position - we center it
                const isMobile = window.innerWidth < screenSizes.xl

                const slideGap = isMobile
                  ? MOBILE_SLIDE_GAP_VW
                  : DESKTOP_SLIDE_GAP_VW

                const padding =
                  (isMobile
                    ? MOBILE_SLIDES_PADDING_PX
                    : DESKTOP_SLIDES_PADDING_PX) / 2
                const lastSlidePosition = interpolate(
                  slideScrollPercentage,
                  0,
                  slideGap,
                )
                currentSlide.style.transform = `translate3d(calc(-${lastSlidePosition}vw - ${padding}px), 0%, 0)`
              } else {
                // all other slides have the same final position
                currentSlide.style.transform = `translate3d(-${slideScrollPercentage}%, 0%, 0)`
              }

              /**
               * Next slide animation
               */
              const nextSLide = visibleSlidesList[currentSlideIndex + 1]
              if (!nextSLide) return

              const nextSlideContent = nextSLide.querySelector(
                `.${s.slide}`,
              ) as HTMLElement | null
              const nextSLideImage = nextSLide.querySelector(
                `.${contentStyles.slideMedia}`,
              ) as HTMLElement | null

              if (!nextSlideContent || !nextSLideImage) return

              // Image animation - filter
              const brightnessValue = interpolate(slideScrollPercentage, 1.5, 1)
              const greyScaleValue = interpolate(slideScrollPercentage, 100, 0)
              nextSLideImage.style.filter = `grayscale(${greyScaleValue}%) brightness(${brightnessValue})`

              // Content animation - background color and position
              const colorValue = interpolateRGB(
                slideScrollPercentage,
                { r: 230, g: 230, b: 230 },
                { r: 18, g: 190, b: 106 },
              )
              const position = interpolate(slideScrollPercentage, 0, 2)
              nextSlideContent.style.backgroundColor = `rgb(${colorValue.r}, ${colorValue.g}, ${colorValue.b})`
              nextSlideContent.style.transform = `translate3d(-${position}%, 0%, 0)`
            })
          }
        })
      }
    }
  }

  useEffect(() => {
    if (isSectionInView) {
      window.addEventListener('scroll', handleScroll, { passive: true })
    }

    return () => {
      window.removeEventListener('scroll', handleScroll)
    }
  }, [isSectionInView])

  if (!title || !slides) {
    return null
  }

  return (
    <SectionContainer appearance="WITH_BACKGROUND">
      <div ref={refSectionContainer}>
        <div
          className={s.fixedWrap}
          ref={refFixedContainer}
          style={assignInlineVars({
            [s.allSlidesPercentageVar]: `0%`,
            [s.currentSlideIndexVar]: `0`,
          })}
        >
          <div className={s.root}>
            <section className={s.presentationAreaContainer}>
              <ContentContainer>
                <header className={headerStyles.header}>
                  <h3 className={headerStyles.titleMobile}>{title}</h3>
                  <h3 className={headerStyles.titleDesktop}>{title}</h3>
                  <div className={headerStyles.counter}>
                    <div className={headerStyles.counterSlidingPart}>
                      <span
                        className={
                          headerStyles.counterCurrentValuePartPlaceholder
                        }
                        aria-hidden="true"
                      >
                        {'0'.padStart(2, '0')}
                      </span>

                      <span
                        className={headerStyles.counterValues}
                        ref={slidingCounterPartRef}
                      >
                        {Array.from(
                          { length: slides.length },
                          (_, i) => i + 1,
                        ).map((value) => {
                          return (
                            <span
                              key={value}
                              className={headerStyles.counterCurrentValuePart}
                            >
                              {value.toString().padStart(2, '0')}
                            </span>
                          )
                        })}
                      </span>
                    </div>

                    <span className={headerStyles.counterPart}>/</span>
                    <span className={headerStyles.counterPart}>
                      {slides.length.toString().padStart(2, '0')}
                    </span>
                  </div>
                </header>
              </ContentContainer>
              <article className={s.presentationSlidesAreaWrap}>
                <ul className={s.rootSlidesList} ref={rootSlidesListRef}>
                  {slides.map((slide, index) => {
                    const isFirst = index === 0
                    const backgroundColor = isFirst
                      ? theme.colors.green
                      : theme.colors.gray90

                    const filter = isFirst
                      ? 'grayscale(0%) brightness(1)'
                      : 'grayscale(100%) brightness(1.5)'

                    return (
                      <li
                        key={index}
                        className={s.rootSlideItem}
                        style={assignInlineVars({
                          [s.slideColor]: theme.colors.green,
                          [s.slideIndex]: index.toString(),
                          [s.slidesCount]: slides.length.toString(),
                        })}
                      >
                        <div className={s.mainSlideContainer}>
                          <div
                            className={s.slide}
                            style={{
                              backgroundColor: backgroundColor,
                            }}
                          >
                            <div className={s.slideContent}>
                              <div className={contentStyles.slideHeader}>
                                <h3 className={contentStyles.slideTitle}>
                                  {slide.title}
                                </h3>
                                <h3
                                  className={contentStyles.slideTitleTabletOnly}
                                >
                                  {slide.title}
                                </h3>
                                <p className={contentStyles.slideDescription}>
                                  {slide.description}
                                </p>
                              </div>
                              {slide.mediaContent?.mediaPayload !==
                                undefined && (
                                <div
                                  className={contentStyles.slideMedia}
                                  style={{
                                    filter: filter,
                                    willChange: 'filter',
                                  }}
                                >
                                  <Media
                                    mediaPayload={
                                      slide.mediaContent.mediaPayload
                                    }
                                    mediaPayloadMobile={
                                      slide.mediaContent.mediaPayloadMobile
                                    }
                                    priority={priority}
                                  />
                                </div>
                              )}
                            </div>
                          </div>
                        </div>
                      </li>
                    )
                  })}
                </ul>
              </article>
            </section>
          </div>
          <ul className={s.scrollSlidesList} ref={scrollSlidesListRef}>
            {slides.map((_, index) => (
              <li key={index} className={s.scrollSlideItem} />
            ))}
          </ul>
        </div>
      </div>
    </SectionContainer>
  )
}
