'use client'

import {
  ContentContainer,
  Disclosure,
  DisclosuresList,
  Link,
  Media,
  SectionContainer,
  TimeDisplay,
} from '@pienso/components'
import { AgendaItem } from '@pienso/models'
import { useStickyContent } from '@pienso/sticky-content-provider'
import { theme } from '@pienso/styles'
import { format } from 'date-fns'
import { useEffect } from 'react'
import { BiMinus, BiPlus } from 'react-icons/bi'
import { useInView } from 'react-intersection-observer'
import * as s from './SectionEventAgenda.css'
import { SectionEventAgendaProps } from './SectionEventAgenda.model'
import { isoDateToDate } from '@pienso/utils'

const AgendaItemHeader = ({
  isOpen,
  headingLevel,
  label,
  time,
  tags,
  speakers,
  disabled,
}: {
  isOpen: boolean
  headingLevel: number
  label?: string
  time?: string
  tags?: string[]
  speakers?: AgendaItem['speakers']
  disabled?: boolean
}) => {
  const Heading = `h${headingLevel}` as `h1`
  return (
    <div className={s.agendaItemHeader}>
      {!!time && (
        <TimeDisplay clock="12" time={time} className={s.agendaItemTime} />
      )}
      <Heading className={s.agendaItemTitle}>{label}</Heading>
      {isOpen ? (
        <BiMinus
          className={s.agendaAccordionIcon({ disabled, type: 'minus' })}
        />
      ) : (
        <BiPlus className={s.agendaAccordionIcon({ disabled, type: 'plus' })} />
      )}
      {tags && (
        <div className={s.agendaItemTags}>
          {tags?.map((tag) => {
            return (
              <span key={tag} className={s.agendaItemTag}>
                {tag}
              </span>
            )
          })}
        </div>
      )}
      {speakers && (
        <div className={s.agendaItemSpeakers}>
          {speakers.map((speaker) => {
            const media = speaker.photo
            return (
              <div key={speaker._id} className={s.agendaItemSpeaker}>
                {media?.mediaPayload ? (
                  <Media
                    mediaPayload={media.mediaPayload}
                    mediaPayloadMobile={media.mediaPayloadMobile}
                    layout="fill"
                    sizes="32px"
                    className={s.agendaItemSpeakerPhoto}
                  />
                ) : (
                  <div className={s.agendaItemSpeakerPhoto}></div>
                )}
                <span className={s.agendaItemSpeakerName}>
                  {speaker.name}
                  {speaker.title ? `, ${speaker.title}` : ''}
                </span>
              </div>
            )
          })}
        </div>
      )}
    </div>
  )
}

export const SectionEventAgenda = ({
  event,
  isFirst,
  agendaFile,
  anchor,
  title,
}: SectionEventAgendaProps) => {
  const HeadlineComponent = isFirst ? 'h1' : 'h2'
  const DayHeadline = isFirst ? 'h2' : 'h3'
  const { ref, inView } = useInView()
  const { disable: stickyDisable, enable: stickyEnable } = useStickyContent()
  useEffect(() => {
    if (inView) {
      stickyDisable()
    } else {
      stickyEnable()
    }
  }, [inView, stickyDisable, stickyEnable])
  return (
    <SectionContainer
      appearance="WITH_BACKGROUND"
      backgroundColor={theme.colors.lightestGray}
      id={anchor}
    >
      <div className={s.wrap} ref={ref}>
        {agendaFile?.payload && (
          <div className={s.stickyLinkWrap}>
            <ContentContainer>
              <Link
                {...agendaFile.payload}
                appearance="primaryBlue"
                className={s.downloadLink}
              >
                {agendaFile?.payload?.label ?? 'Download Agenda'}
              </Link>
            </ContentContainer>
          </div>
        )}
        {title && (
          <div className={s.headerWrap}>
            <ContentContainer>
              <HeadlineComponent className={s.title}>{title}</HeadlineComponent>
            </ContentContainer>
          </div>
        )}
        <div className={s.contentWrap}>
          {event?.agenda?.map((day) => {
            return (
              <div key={day._id}>
                <div className={s.titleWrap}>
                  <ContentContainer>
                    <DayHeadline className={s.dayTitle}>
                      {format(isoDateToDate(day.date ?? ''), 'd MMM')}
                    </DayHeadline>
                  </ContentContainer>
                </div>
                <DisclosuresList
                  allowMultipleActiveItems
                  key={day._id}
                  className={s.agendaDayWrap}
                >
                  {day.sessions?.map((agendaItem) => {
                    const id = agendaItem._key
                    return (
                      <div className={s.agendaItemBackground} key={id}>
                        <ContentContainer>
                          <div className={s.agendaItemWrap}>
                            <div className={s.disclosureWrap}>
                              <Disclosure
                                disabled={!agendaItem.description}
                                itemId={id}
                                bodyClassName={s.agendaItemBody}
                                label={agendaItem.title}
                                headingLevel={isFirst ? 3 : 4}
                                className={`${s.disclosureButton} ${
                                  !agendaItem.description ? 'disabled' : ''
                                }`}
                                innerBodyClassName={s.agendaItemContentWrap}
                                renderHeader={(headerProps) => (
                                  <AgendaItemHeader
                                    {...headerProps}
                                    time={agendaItem.time?.start}
                                    tags={agendaItem.tags}
                                    speakers={agendaItem.speakers}
                                    disabled={!agendaItem.description}
                                  />
                                )}
                              >
                                {agendaItem.description ? (
                                  <p className={s.agendaItemDescription}>
                                    {agendaItem.description}
                                  </p>
                                ) : null}
                              </Disclosure>
                            </div>
                          </div>
                        </ContentContainer>
                      </div>
                    )
                  }) || []}
                </DisclosuresList>
              </div>
            )
          })}
        </div>
        <ContentContainer>
          {agendaFile?.payload && (
            <div className={s.downloadLinkWrap}>
              <Link
                {...agendaFile.payload}
                appearance="primaryBlue"
                className={s.downloadLink}
              >
                {agendaFile?.payload?.label ?? 'Download Agenda'}
              </Link>
            </div>
          )}
        </ContentContainer>
      </div>
    </SectionContainer>
  )
}
