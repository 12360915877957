import { FC, ReactNode } from 'react'
import * as styles from './layout.css'
import { assignInlineVars } from '@vanilla-extract/dynamic'

export type SectionContainerAppearance =
  | 'DEFAULT'
  | 'WITH_BACKGROUND'
  | 'EVEN_PADDING'

type SectionContainerProps = {
  id?: string
  children: ReactNode
  backgroundColor?: string
  appearance?: SectionContainerAppearance
  overflowHidden?: boolean
}
export const SectionContainer: FC<SectionContainerProps> = ({
  id,
  children,
  backgroundColor,
  appearance = 'DEFAULT',
  overflowHidden,
}) => {
  const style = {
    ...assignInlineVars({
      [styles._backgroundColor]: backgroundColor || 'transparent',
    }),
  }

  return (
    <section
      id={id}
      className={styles.sectionContainer({ appearance, overflowHidden })}
      style={style}
    >
      {children}
    </section>
  )
}
