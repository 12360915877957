import "../../../../../node_modules/@vanilla-extract/webpack-plugin/vanilla.virtual.css?%7B%22fileName%22%3A%22..%2F..%2Flibs%2Fstyles%2Fsrc%2Flib%2Ftheme.css.ts.vanilla.css%22%2C%22source%22%3A%22%23H4sIAAAAAAAAA22USW5jMQxE9zlFAVoH%2BOIfVVnZsXONhqc4cTw7U6fRd29Qq6b4VbsHqUiRlHg9nd7x5w64v%2F%2FVHw%2B7%2FloRIU5UD%2F%2FjSATpVAYLEeq8DK6J0FQqgxvFM5XBLRHapDK4I0KXl8E9EfpaZfBAhKFSGZwUP6oMXhAh5WXwkgiTTmXwigjTWmXwWvGTyuANER7zMviZCLOkMnhLhHmnMviFCE%2BiMviVCJVMp100eJfxpG97g98yTrGxmewVV9M0s96HjCcyHQw%2B5rzn%2Fdze8pQTjENlQ551fGqVwZfxy1%2FVZFAZfFOcl8HvhL32BxFtuz8JseSLKJr8rafspt%2BKWoN%2B1MnuihVRlywSwzDY%2FKMQ1fnbspqQkjVEU7KW6ErWEUPJes25hAMRXZRERBdmQUQXZ0lEF2illSjhmhAXaEOIC%2FRMiPPcaiVL%2BELUzvOVqF2eO6Jxx9%2BIxgXaE63zPBCt8zwSnfM8EZ270ZnoneeFGNzxK5FcoFuev5LqcPsy64T7kn4qdVf90jY7B532xjnowPu6%2FOhMlA5SKS2jSdRRcXuFiKn0FR19l5k0OhjOtyUkub0dUfdub080bohlyC%2FXWaSxoslipOuyJNqyZLIi6qYu6XqsmbIhuljCsYcgW8J1R15G3pG8jsHdyOuQN%2F%2BryN7%2FIHIY%2BQLkOBbmpJ9oSiU%2BE23JLiPBrzptJbzlz%2FRwe7j7%2Bw8BJQpvhwgAAA%3D%3D%22%7D"
import "../../../../../node_modules/@vanilla-extract/webpack-plugin/vanilla.virtual.css?%7B%22fileName%22%3A%22..%2F..%2Flibs%2Fsections%2Fsection-slider%2Fsrc%2Flib%2FSectionSlider.css.ts.vanilla.css%22%2C%22source%22%3A%22%23H4sIAAAAAAAAA41Uy5LaMBC88xVz2SpcQazMYyHaS%2F4kJeyxrUWWXJLAJqn995Rkm0XgkByMjdTTM92a0fJnKrqjFOUKfs8AGm2FE1oxMCi5E2d8n33OliNoHUC5sI3kFwalEfl7FGWdyI4Xv%2BZ0w4D6L31GU0jdko5BJfIclV%2BtUJSVY5BSeq6iJLvpJCPNLYnfIw7rRnKHJNPyVCvra2%2BQu%2FlmAelye24TSAvzsBgK53kuVEkkFr6UfdNFleynKwlZ%2B2QMMi6z%2BZmbOSFj2CaBb5Am8Aq24QrizbeQ%2BMCzY2n0SQUibdgdahtQrZCSZBVXJbKHkAU4w5UttKmjor%2F3B9kr60X1P3QQ%2BDdFVx%2BNbi0LnvGT0xE5f%2BIIN8gZpPAKaRRzCDGPkAmeoGhopas6oMuVBSkUcvPgyo0H8PVnWPdq1vmcviwgPMHUX0SoHLvJk3tLgMD9YSaRmuyZAyenSd%2BjRrd%2BWQrriHUXiQyUVvE05dNU42RsKe2Py2ZGS0ms4g3hUpS%2B61A5NBEbxmyFxBDs3yQXBrPe1r5v%2Fc7HyTpRXEimlUPlmO%2FWDMkBXYvDePHm2pg7VX%2FsTNok07N4U0jxXFZK6YvH%2F6gxFxzmtVCkFbmrwhZtuiTE37kUxQ%2B%2BfN7CigFW846M0H24WjxwOt16It3uymNKoRjQYQhG7iE2cmW1T3rAP%2B%2BjzeNttBnuopuZpbD%2FX4m7L4l%2FANabv%2BXLBQAA%22%7D"
export var DESKTOP_SLIDES_PADDING_PX = 80;
export var DESKTOP_SLIDE_GAP_VW = 4;
export var MOBILE_SLIDES_PADDING_PX = 18;
export var MOBILE_SLIDE_GAP_VW = 1.5;
export var allSlidesPercentageVar = 'var(--_1ixklig0)';
export var currentSlideIndexVar = 'var(--_1ixklig1)';
export var fixedWrap = '_1ixklig2';
export var mainSlideContainer = '_1ixklig7';
export var presentationAreaContainer = '_1ixklige';
export var presentationSlidesAreaWrap = '_1ixkligf';
export var root = '_1ixklig3';
export var rootSlideItem = '_1ixkligb';
export var rootSlidesList = '_1ixkliga';
export var scrollSlideItem = '_1ixkligd';
export var scrollSlidesList = '_1ixkligc';
export var slide = '_1ixklig8';
export var slideColor = 'var(--_1ixklig5)';
export var slideContent = '_1ixklig9';
export var slideIndex = 'var(--_1ixklig4)';
export var slidesCount = 'var(--_1ixklig6)';