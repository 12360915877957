'use client'

import { FC, useState, ReactNode, createContext, useContext } from 'react'
import { root } from './DisclosuresList.css'

export type Props = {
  allowMultipleActiveItems?: boolean
  initiallyOpenedItemsIds?: string[]
  children: ReactNode | ReactNode[]
  className?: string
}
const DisclosureContext = createContext<{
  isOpen: (itemId: string) => boolean
  setOpen: (itemId: string, isOpen: boolean) => void
  requestOpen: (itemId: string) => void
  requestClose: (itemId: string) => void
} | null>(null)

export const useDisclosureContext = () => {
  const data = useContext(DisclosureContext)
  if (!data) {
    throw new Error(
      'useDisclosureContext must be used within a DisclosuresList',
    )
  }
  return data
}

export const DisclosuresList: FC<Props> = ({
  allowMultipleActiveItems = true,
  initiallyOpenedItemsIds = [],
  children,
  className,
}) => {
  const [activeItemsIds, setActiveItemsIds] = useState<string[]>(
    initiallyOpenedItemsIds,
  )
  const handleSetOpen = (itemId: string, isOpen: boolean) => {
    if (isOpen) handleRequestOpen(itemId)
    else handleRequestClose(itemId)
  }
  const handleRequestOpen = (itemId: string) => {
    const result = allowMultipleActiveItems
      ? activeItemsIds.includes(itemId)
        ? [...activeItemsIds]
        : [...activeItemsIds, itemId]
      : [itemId]
    return setActiveItemsIds(result)
  }

  const handleRequestClose = (itemId: string) => {
    const result = allowMultipleActiveItems
      ? [...activeItemsIds.filter((_) => _ !== itemId)]
      : []

    return setActiveItemsIds(result)
  }

  const isActive = (itemId: string) => {
    return activeItemsIds.includes(itemId)
  }

  const classes = `${root} ${className}`

  return (
    <DisclosureContext.Provider
      value={{
        isOpen: isActive,
        requestOpen: handleRequestOpen,
        setOpen: handleSetOpen,
        requestClose: handleRequestClose,
      }}
    >
      <div className={classes}>{children}</div>
    </DisclosureContext.Provider>
  )
}
