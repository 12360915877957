'use client'
import {
  Button,
  ContentContainer,
  Media,
  Modal,
  PortableText,
  SectionContainer,
} from '@pienso/components'
import { EventSpeaker } from '@pienso/models'
import { useEffect, useRef, useState } from 'react'
import * as s from './SectionSpeakers.css'
import { SectionSpeakersProps } from './SectionSpeakers.model'
import { useMediaQuery } from '@pienso/styles'
import { SpeakerDetails } from './components/SpeakerDetails'

export const SectionSpeakers = ({
  title,
  text,
  priority,
  isFirst,
  event,
}: SectionSpeakersProps) => {
  const HeadlineComponent = isFirst ? 'h1' : 'h2'
  const [speakerPanelOpen, setSpeakerPanelOpen] = useState(false)
  const [selectedSpeaker, setSelectedSpeaker] = useState<EventSpeaker | null>(
    null,
  )
  const isLg = useMediaQuery('lg')
  const isModalOpen = !isLg && speakerPanelOpen
  const isDesktopPanelOpen = isLg && speakerPanelOpen

  const previousFocus = useRef<HTMLElement | null>()
  const sidePanelFocus = useRef<HTMLButtonElement>(null)
  const handleKey = (event: KeyboardEvent) => {
    if (event.key === 'Escape') {
      handleSetInfo(null)
    }
  }
  useEffect(() => {
    document.addEventListener('keydown', handleKey)
    return () => {
      document.removeEventListener('keydown', handleKey)
    }
  })

  const handleSetInfo = (speaker: EventSpeaker | null) => {
    setSpeakerPanelOpen(speaker !== null)
    if (speaker === null) {
      setSelectedSpeaker(null)

      // restore previous focus
      previousFocus.current?.focus({ preventScroll: true })
    } else {
      setSelectedSpeaker(speaker)
      // save previous focus
      previousFocus.current = document.activeElement as HTMLElement
      // focus 'x' button in the panel
      sidePanelFocus.current?.focus({ preventScroll: true })
      sidePanelFocus.current?.blur()
    }
  }

  if (!event?.speakers || event.speakers.length === 0) return null
  return (
    <SectionContainer appearance="EVEN_PADDING">
      <ContentContainer>
        <div className={s.contentWrap}>
          <div className={s.infoWrap}>
            <HeadlineComponent className={s.title}>{title}</HeadlineComponent>
            <div className={s.infoStickyWrap}>
              <div className={s.infoStickyContainer}>
                <div className={s.textWrap({ isHidden: isDesktopPanelOpen })}>
                  <PortableText value={text} />
                </div>
                <Button
                  tabIndex={speakerPanelOpen ? 0 : -1}
                  aria-hidden={true}
                  ref={sidePanelFocus}
                  className={s.focusButton}
                />
                <SpeakerDetails
                  speaker={selectedSpeaker}
                  handleSetInfo={handleSetInfo}
                  isVisible={isDesktopPanelOpen}
                />
              </div>
            </div>
          </div>
          <ul className={s.speakerList}>
            {event?.speakers?.map((speaker, index) => {
              const isSelected =
                selectedSpeaker?._id === speaker._id && speakerPanelOpen
              return (
                <li
                  key={speaker._id}
                  className={s.speakerCard({
                    selected: isSelected,
                  })}
                  aria-label={speaker.name}
                  onClick={() => handleSetInfo(isSelected ? null : speaker)}
                >
                  <div>
                    {speaker.photo?.mediaPayload && (
                      <Media
                        className={s.speakerThumbnail}
                        mediaPayload={speaker.photo.mediaPayload}
                        layout="fill"
                        priority={priority && index < 6}
                      />
                    )}
                  </div>
                  <div className={s.speakerHeadline}>
                    <div>
                      <p className={s.speakerName}>
                        <span>{speaker.name}</span>
                        {speaker.title && <span>, {speaker.title}</span>}
                      </p>
                      <p className={s.speakerDescription}>
                        {speaker.description}
                      </p>
                    </div>
                    <Button
                      type="button"
                      appearance="secondaryBlack"
                      aria-label={
                        isSelected
                          ? `Close ${speaker.name}`
                          : `Read more about ${speaker.name}`
                      }
                      className={s.speakerButton({ isSelected })}
                      onClick={(e) => {
                        e.preventDefault()
                        e.stopPropagation()
                        handleSetInfo(isSelected ? null : speaker)
                      }}
                    >
                      {isSelected ? 'Close' : 'Read more'}
                    </Button>
                  </div>
                </li>
              )
            })}
          </ul>
        </div>
      </ContentContainer>
      <Modal
        size="stretch-full"
        isOpen={isModalOpen}
        onRequestClose={() => handleSetInfo(null)}
      >
        <div className={s.modalContentWrap}>
          <ContentContainer>
            <SpeakerDetails
              speaker={selectedSpeaker}
              handleSetInfo={handleSetInfo}
              isVisible={isModalOpen}
            />
          </ContentContainer>
        </div>
      </Modal>
    </SectionContainer>
  )
}
