'use client'

import * as s from './SectionSponsors.css'
import { SectionSponsorsProps } from './SectionSponsors.model'
import { SanityImage, SectionContainer } from '@pienso/components'

export const SectionSponsors = ({ logos }: SectionSponsorsProps) => {
  return (
    <SectionContainer appearance="WITH_BACKGROUND">
      <div className={s.wrap}>
        {logos && logos.length > 0 && (
          <div className={s.logoImagesWrap}>
            {logos.map((logo, index) => {
              if (!logo || !logo.mediaPayload.image?.asset) return null

              return (
                <SanityImage
                  {...logo}
                  className={`${s.logoWrap} sectionContainerImage`}
                  key={`sponsorLogo-${index}`}
                />
              )
            })}
          </div>
        )}
      </div>
    </SectionContainer>
  )
}
