'use client'

import {
  Disclosure,
  DisclosureItemProps,
  DisclosuresList,
  RichText,
} from '@pienso/components'
import { SectionTheme } from '@pienso/models'
import { useSyncExternalStore } from 'react'
import * as s from './SectionAccordions.css'
import { type SectionAccordionsItem } from './SectionAccordions.model'

function Accordion({
  accordionItems,
  headingLevel,
  sectionTheme,
}: {
  accordionItems: SectionAccordionsItem['accordionItems']
  headingLevel: DisclosureItemProps['headingLevel']
  sectionTheme?: SectionTheme
}) {
  // `useSyncExternalStore` allows us to avoid hydration mismatches
  // https://tkdodo.eu/blog/avoiding-hydration-mismatches-with-use-sync-external-store#usesyncexternalstore
  const initiallyOpenItemId = useSyncExternalStore(
    // eslint-disable-next-line @typescript-eslint/no-empty-function
    () => () => {},
    () => {
      const matchingItem = accordionItems?.find(
        (item) => item.anchor === window.location.hash.slice(1),
      )

      return matchingItem?._key ?? null
    },
    () => null,
  )

  const initiallyOpenedItemsIds =
    initiallyOpenItemId && accordionItems
      ? accordionItems
          .filter((item) => item._key === initiallyOpenItemId)
          .map((item) => item._key)
      : undefined

  return (
    <div className={s.accordion}>
      <DisclosuresList
        key={initiallyOpenItemId}
        initiallyOpenedItemsIds={initiallyOpenedItemsIds}
      >
        {accordionItems?.map((item) => (
          <Disclosure
            headerId={item.anchor}
            key={item._key}
            itemId={item._key}
            label={item.term}
            headingLevel={headingLevel}
          >
            <RichText value={item.description} sectionTheme={sectionTheme} />
          </Disclosure>
        ))}
      </DisclosuresList>
    </div>
  )
}

export { Accordion }
