import "../../../../../node_modules/@vanilla-extract/webpack-plugin/vanilla.virtual.css?%7B%22fileName%22%3A%22..%2F..%2Flibs%2Fstyles%2Fsrc%2Flib%2Ftheme.css.ts.vanilla.css%22%2C%22source%22%3A%22%23H4sIAAAAAAAAA22USW5jMQxE9zlFAVoH%2BOIfVVnZsXONhqc4cTw7U6fRd29Qq6b4VbsHqUiRlHg9nd7x5w64v%2F%2FVHw%2B7%2FloRIU5UD%2F%2FjSATpVAYLEeq8DK6J0FQqgxvFM5XBLRHapDK4I0KXl8E9EfpaZfBAhKFSGZwUP6oMXhAh5WXwkgiTTmXwigjTWmXwWvGTyuANER7zMviZCLOkMnhLhHmnMviFCE%2BiMviVCJVMp100eJfxpG97g98yTrGxmewVV9M0s96HjCcyHQw%2B5rzn%2Fdze8pQTjENlQ551fGqVwZfxy1%2FVZFAZfFOcl8HvhL32BxFtuz8JseSLKJr8rafspt%2BKWoN%2B1MnuihVRlywSwzDY%2FKMQ1fnbspqQkjVEU7KW6ErWEUPJes25hAMRXZRERBdmQUQXZ0lEF2illSjhmhAXaEOIC%2FRMiPPcaiVL%2BELUzvOVqF2eO6Jxx9%2BIxgXaE63zPBCt8zwSnfM8EZ270ZnoneeFGNzxK5FcoFuev5LqcPsy64T7kn4qdVf90jY7B532xjnowPu6%2FOhMlA5SKS2jSdRRcXuFiKn0FR19l5k0OhjOtyUkub0dUfdub080bohlyC%2FXWaSxoslipOuyJNqyZLIi6qYu6XqsmbIhuljCsYcgW8J1R15G3pG8jsHdyOuQN%2F%2BryN7%2FIHIY%2BQLkOBbmpJ9oSiU%2BE23JLiPBrzptJbzlz%2FRwe7j7%2Bw8BJQpvhwgAAA%3D%3D%22%7D"
import "../../../../../node_modules/@vanilla-extract/webpack-plugin/vanilla.virtual.css?%7B%22fileName%22%3A%22..%2F..%2Flibs%2Fstyles%2Fsrc%2Flib%2Ftypography.css.ts.vanilla.css%22%2C%22source%22%3A%22%23H4sIAAAAAAAAA63Uz26CMBzA8TtP0aMe2vQPiOJlrwKjQI1UhgSFhXdfZBrtftUU5rX5pp%2B2lJIqU13yRdG3h1B20A0%2BSZUXTYRCSrfe4JHfgMEgMAIOA98IxD3I4lLtuwi1cb3AeBwqZJwqnS%2B3t%2Baoehkhf12dL0N7pSUurvNSshnHZNPIGh%2Br%2BFPpPEKYEipk%2BUCGs0g6jWSBYSbvNANHNJuDCmFBmaO4myNy29dkhD7dJjdMPct03uXjZa1fUskh7f46bPOvvbXTwZUVFNwN7KeDggRW8umdMUX2%2BgWwk1bQcYvs9f9vBdks8KOUqYrRolQan1TaFJelU1qdl%2BMKjBfQ9OgVHO5ZCLNwBarEqcpgFYSg2sHKF6DSsBIcVDWsuA%2Bq1nIUa1D1lgrOxWznasks58roLRt%2BAIdlYAUJBwAA%22%7D"
import "../../../../../node_modules/@vanilla-extract/webpack-plugin/vanilla.virtual.css?%7B%22fileName%22%3A%22..%2F..%2Flibs%2Fcomponents%2Fsrc%2Flib%2FEditorialRichText%2FEditorialRichText.css.ts.vanilla.css%22%2C%22source%22%3A%22%23H4sIAAAAAAAAA5WUUVLCMBCG3znFjjPOgE6whSIaXryJE5JtWU0TTCOCDtfw2bN5EqetiAll0NfN%2Fl%2F%2F%2FZPt8D4VrjSbIuE5ucozuSCt4K0HUApXkGFzbeUjq7xwnsNKuD5j91NTPkxd%2BjSY9ba94R6hxXECGhXrV6E%2B%2FRat2Qspv%2BBwkyTLddAyPuDGzMdBVJiEH8n%2BT4jGnAQE670tYwSFiuvTCgwV09MKESpuG0VujWeV32jkQF5okkGTaJo8rj1TKK0Tnqzh8GwUOk0Gg%2Bb5nviCVCw8h7nVKuiRTY%2B02rrIXxbaU3%2BLAGAplCJTMI35wXtrQ9IE%2F8FGRvC0YtEY0VT9RGlslA3uLeSczzG3Dr%2BjMB6N53D2%2Bf5xVnMUVUstNhzI1BG3b64%2BWKHzJIVmQlNhOJSklMbZzy3SK3JIhiMsZ3vHbSwsa4u7sFx7PeO6uu3dlahIQL8ks9ukdFyv0qCxeHxfuzZWCi37I7g42IkmpO1v3rgDcvjL6FjPiJP9idPKuqdNOqZNQ2obZDPdJDmHSxhlyzVcwWgH%2FgLebhh3GgUAAA%3D%3D%22%7D"
import { createRuntimeFn as _7a468 } from '@vanilla-extract/recipes/createRuntimeFn';
export var halfSize = '_1armnyg1';
export var heading = _7a468({defaultClassName:'_1armnyg1',variantClassNames:{level:{'2':'_1armnyg3 pfiybqr pfiybq0','5':'_1armnyg4 pfiybqn pfiybq0'}},defaultVariants:{},compoundVariants:[]});
export var label = '_1armnyg7 pfiybq17 pfiybq1';
export var list = '_1armnygd pfiybqv';
export var listItem = '_1armnygf pfiybqv pfiybq2';
export var mark = _7a468({defaultClassName:'_1armnyg8',variantClassNames:{type:{em:'_1armnyg9',underline:'_1armnyga',strong:'_1armnygb','attention-text':'_1armnygc pfiybqv'}},defaultVariants:{},compoundVariants:[]});
export var paragraph = '_1armnyg5 _1armnyg1 pfiybqv pfiybq2';
export var root = '_1armnyg0';
export var small = '_1armnyg6 pfiybq13 pfiybq1';
export var unorderedList = '_1armnyge';