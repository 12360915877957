import "../../../../../node_modules/@vanilla-extract/webpack-plugin/vanilla.virtual.css?%7B%22fileName%22%3A%22..%2F..%2Flibs%2Fstyles%2Fsrc%2Flib%2Ftheme.css.ts.vanilla.css%22%2C%22source%22%3A%22%23H4sIAAAAAAAAA22USW5jMQxE9zlFAVoH%2BOIfVVnZsXONhqc4cTw7U6fRd29Qq6b4VbsHqUiRlHg9nd7x5w64v%2F%2FVHw%2B7%2FloRIU5UD%2F%2FjSATpVAYLEeq8DK6J0FQqgxvFM5XBLRHapDK4I0KXl8E9EfpaZfBAhKFSGZwUP6oMXhAh5WXwkgiTTmXwigjTWmXwWvGTyuANER7zMviZCLOkMnhLhHmnMviFCE%2BiMviVCJVMp100eJfxpG97g98yTrGxmewVV9M0s96HjCcyHQw%2B5rzn%2Fdze8pQTjENlQ551fGqVwZfxy1%2FVZFAZfFOcl8HvhL32BxFtuz8JseSLKJr8rafspt%2BKWoN%2B1MnuihVRlywSwzDY%2FKMQ1fnbspqQkjVEU7KW6ErWEUPJes25hAMRXZRERBdmQUQXZ0lEF2illSjhmhAXaEOIC%2FRMiPPcaiVL%2BELUzvOVqF2eO6Jxx9%2BIxgXaE63zPBCt8zwSnfM8EZ270ZnoneeFGNzxK5FcoFuev5LqcPsy64T7kn4qdVf90jY7B532xjnowPu6%2FOhMlA5SKS2jSdRRcXuFiKn0FR19l5k0OhjOtyUkub0dUfdub080bohlyC%2FXWaSxoslipOuyJNqyZLIi6qYu6XqsmbIhuljCsYcgW8J1R15G3pG8jsHdyOuQN%2F%2BryN7%2FIHIY%2BQLkOBbmpJ9oSiU%2BE23JLiPBrzptJbzlz%2FRwe7j7%2Bw8BJQpvhwgAAA%3D%3D%22%7D"
import "../../../../../node_modules/@vanilla-extract/webpack-plugin/vanilla.virtual.css?%7B%22fileName%22%3A%22..%2F..%2Flibs%2Fstyles%2Fsrc%2Flib%2Fgrids.css.ts.vanilla.css%22%2C%22source%22%3A%22Ll8xeDJjd2I5MCB7CiAgZGlzcGxheTogZ3JpZDsKICBncmlkLXRlbXBsYXRlLWNvbHVtbnM6IHJlcGVhdCg0LCAxZnIpOwogIGNvbHVtbi1nYXA6IHZhcigtLV83bm1qN3IxOCk7Cn0KQG1lZGlhIChtaW4td2lkdGg6IDc0MHB4KSB7CiAgLl8xeDJjd2I5MCB7CiAgICBncmlkLXRlbXBsYXRlLWNvbHVtbnM6IHJlcGVhdCgxMiwgMWZyKTsKICAgIGNvbHVtbi1nYXA6IHZhcigtLV83bm1qN3IxZSk7CiAgfQp9%22%7D"
import "../../../../../node_modules/@vanilla-extract/webpack-plugin/vanilla.virtual.css?%7B%22fileName%22%3A%22..%2F..%2Flibs%2Fstyles%2Fsrc%2Flib%2Ftypography.css.ts.vanilla.css%22%2C%22source%22%3A%22%23H4sIAAAAAAAAA63Uz26CMBzA8TtP0aMe2vQPiOJlrwKjQI1UhgSFhXdfZBrtftUU5rX5pp%2B2lJIqU13yRdG3h1B20A0%2BSZUXTYRCSrfe4JHfgMEgMAIOA98IxD3I4lLtuwi1cb3AeBwqZJwqnS%2B3t%2Baoehkhf12dL0N7pSUurvNSshnHZNPIGh%2Br%2BFPpPEKYEipk%2BUCGs0g6jWSBYSbvNANHNJuDCmFBmaO4myNy29dkhD7dJjdMPct03uXjZa1fUskh7f46bPOvvbXTwZUVFNwN7KeDggRW8umdMUX2%2BgWwk1bQcYvs9f9vBdks8KOUqYrRolQan1TaFJelU1qdl%2BMKjBfQ9OgVHO5ZCLNwBarEqcpgFYSg2sHKF6DSsBIcVDWsuA%2Bq1nIUa1D1lgrOxWznasks58roLRt%2BAIdlYAUJBwAA%22%7D"
import "../../../../../node_modules/@vanilla-extract/webpack-plugin/vanilla.virtual.css?%7B%22fileName%22%3A%22..%2F..%2Flibs%2Fsections%2Fsection-quote%2Fsrc%2Flib%2FSectionQuote.css.ts.vanilla.css%22%2C%22source%22%3A%22%23H4sIAAAAAAAAA41Sy27CMBC88xV7qQRSTZPwSGUu%2FYzeqiXZBBfHdh0HqCr%2BvYoTWkyI2pPX1szseHfmb3FlT0lUxPA1Adhiti%2BtblTOMi215XBAO2Xsgopmm8l5Mr9cE08Kkamq3lNrQuDCAw3muVAl22rndMUhQ5lNA1q8nwELlZL3Wai1vKsVyjyHlJWnHHfCEasNZsTBWGJHi2YzASit8P9tKsUhhidYBey1Zw9AcUut0JZCjbjA0EXaGde1cEIrDpYkOnGgP3WyGx3Ot1RoS49w%2FYaFI%2BtbfDTaUc0BG6fvM%2Fu9KUfKcdCGFPOkTWAQt7WWTffqtOEQtZWkwvWls6jqQtuKd6VER69TFkfRw8Dyr72fvpnUNV0aX6HRw3JRG4mfHApJp7Zbe7JcWMo6e90yrqbnPYbhSbyPl4pygTCthGJHkbsdh3QZmdPMN7pJ6X9ymixGcgpwvlZcjiiG%2B9VD4qonBqlL29QtBtj1PWyb0CEUe%2BjowOKk93J3aHEUDac2bjXppb4BQGCXVmUEAAA%3D%22%7D"
import { createRuntimeFn as _7a468 } from '@vanilla-extract/recipes/createRuntimeFn';
export var author = 'pfiybqv pfiybq0';
export var authorDescription = 'pfiybqv pfiybq1';
export var backgroundColor = 'var(--_1mrx20f0)';
export var blockquote = '_1mrx20f5 pfiybqr pfiybq1';
export var container = _7a468({defaultClassName:'_1mrx20f2 _1x2cwb90',variantClassNames:{quoteAppearance:{headline3:'_1mrx20f3',headline6:'_1mrx20f4'}},defaultVariants:{},compoundVariants:[]});
export var media = '_1mrx20f6';
export var quote = _7a468({defaultClassName:'_1mrx20f7',variantClassNames:{appearance:{headline6:'pfiybqr pfiybq1',headline3:'pfiybqf pfiybq1'}},defaultVariants:{},compoundVariants:[]});
export var quoteFooter = '_1mrx20fa';
export var root = '_1mrx20f1 pfiybqj pfiybq1';