'use client'

import { forwardRef, PropsWithChildren } from 'react'
import * as s from './DisclosureHeaderDefault.css'
import { theme } from '@pienso/styles'

type HeaderProps = PropsWithChildren<{
  isOpen: boolean
  level: 2 | 3 | 4 | 5 | 6
  onClick?: () => void
  id?: string
}>

export const DisclosureHeaderDefault = forwardRef<HTMLDivElement, HeaderProps>(
  ({ onClick, children, isOpen, level, id }, ref) => {
    const HeadlineComponent = `h${level}` as `h${HeaderProps['level']}`

    return (
      <div className={s.container} onClick={onClick} ref={ref}>
        <HeadlineComponent className={s.label} id={id}>
          {children}
        </HeadlineComponent>
        <ChevronRightIcon isOpen={isOpen} />
      </div>
    )
  },
)

function ChevronRightIcon({ isOpen }: { isOpen: boolean }) {
  return (
    <svg
      viewBox="0 0 12 22"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={s.icon({ isOpen: isOpen })}
    >
      <path
        d="M1 1L10 11L1 21"
        stroke={theme.colors.darkestGray}
        strokeWidth="2.5"
      />
    </svg>
  )
}
