'use client'

import * as s from './SectionLogistics.css'
import dynamic from 'next/dynamic'
import { SectionLogisticsProps } from './SectionLogistics.model'
import { Link, SectionContainer } from '@pienso/components'
import { getSectionStyles } from '@pienso/styles'
import { useInView } from 'react-intersection-observer'
import { useState } from 'react'
const GoogleMap = dynamic(() => import('./Map'))

export const SectionLogistics = ({
  title,
  subtitle,
  ctaLink,
  sectionTheme,
  latitude,
  longitude,
  zoom = 15,
}: SectionLogisticsProps) => {
  const [isHidden, setIsHidden] = useState(true)
  const { sectionBackgroundColor } = getSectionStyles(sectionTheme)
  const API_KEY = process.env.GOOGLE_MAPS_API_KEY

  if (!API_KEY) {
    console.warn('GOOGLE_MAPS_API_KEY is not defined')
  }

  const { ref } = useInView({
    threshold: 0,
    rootMargin: '350px 0px 350px 0px',
    onChange(inView) {
      if (inView) {
        setIsHidden(false)
      } else {
        setIsHidden(true)
      }
    },
  })

  return (
    <SectionContainer appearance="WITH_BACKGROUND">
      <div className={s.wrap} ref={ref}>
        <div
          className={s.textContainer}
          style={{ backgroundColor: sectionBackgroundColor }}
        >
          <p className={s.text}>{title}</p>
          <p className={s.text}>{subtitle}</p>
          {ctaLink?.payload && (
            <Link appearance="primaryWhite" {...ctaLink?.payload}>
              {ctaLink?.payload.label}
            </Link>
          )}
        </div>
        {API_KEY && !isHidden && (
          <div className={s.map}>
            <GoogleMap
              apiKey={API_KEY}
              latitude={latitude}
              longitude={longitude}
              zoom={zoom}
            />
          </div>
        )}
      </div>
    </SectionContainer>
  )
}
