import { FC, PropsWithChildren } from 'react'
import {
  PortableText as SanityPortableText,
  PortableTextComponents,
  PortableTextMarkComponentProps,
} from '@portabletext/react'
import { TypedObject } from '@portabletext/types'
import { Link } from '../Clickable/Link/Link'
import {
  heading,
  list,
  mark,
  paragraph,
  portableText,
  portableTextLink,
} from './PortableText.css'
import classnames from 'classnames'
import { LinkField, PortableTextBlocks } from '@pienso/models'

// eslint-disable-next-line @typescript-eslint/no-explicit-any
type MarkComponent<T extends TypedObject = any> = (
  props: PropsWithChildren<PortableTextMarkComponentProps<T>>,
) => JSX.Element

export type PortableTextProps = {
  value?: PortableTextBlocks
  additionalComponents?: PortableTextComponents
  withStyles?: boolean
  withMargin?: boolean
  className?: string
}

const linkMark: MarkComponent<LinkField & { _type: string }> = ({
  value,
  children,
}) => {
  return (
    <Link {...value?.payload} className={portableTextLink}>
      {children}
    </Link>
  )
}

export const PortableText: FC<PortableTextProps> = ({
  value,
  additionalComponents,
  withStyles = true,
  withMargin = true,
  className,
}) => {
  const components: PortableTextComponents = {
    ...additionalComponents,
    marks: {
      link: linkMark,
      ...(additionalComponents?.marks ? additionalComponents.marks : {}),
      em: ({ children }) => (
        <em className={mark({ type: 'em' })}>{children}</em>
      ),
      underline: ({ children }) => (
        <span className={mark({ type: 'underline' })}>{children}</span>
      ),
      strong: ({ children }) => (
        <strong className={mark({ type: 'strong' })}>{children}</strong>
      ),
      'attention-text': ({ children }) => (
        <span className={mark({ type: 'attention-text' })}>{children}</span>
      ),
      textColor: ({ children, value }) => {
        return <span style={{ color: value.hex }}>{children}</span>
      },
      textHighlight: ({ children, value }) => {
        return <span style={{ background: value.hex }}>{children}</span>
      },
    },
    block: {
      normal: ({ children }) => (
        <p className={paragraph({ withMargin })}>{children}</p>
      ),
      h1: ({ children }) => (
        <h1 className={heading({ level: 1 })}>{children}</h1>
      ),
      h2: ({ children }) => (
        <h2 className={heading({ level: 2 })}>{children}</h2>
      ),
      h3: ({ children }) => (
        <h3 className={heading({ level: 3 })}>{children}</h3>
      ),
      h4: ({ children }) => (
        <h4 className={heading({ level: 4 })}>{children}</h4>
      ),
      h5: ({ children }) => (
        <h5 className={heading({ level: 5 })}>{children}</h5>
      ),
      h6: ({ children }) => (
        <h6 className={heading({ level: 6 })}>{children}</h6>
      ),
    },
    list: {
      bullet: ({ children }) => <ul className={list}>{children}</ul>,
      number: ({ children }) => <ol className={list}>{children}</ol>,
    },
    types: {},
  }

  const portableTextClassName = classnames(
    portableText({ withStyles }),
    className,
  )

  return value ? (
    <div className={portableTextClassName}>
      <SanityPortableText value={value} components={components} />
    </div>
  ) : null
}
