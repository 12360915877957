'use client'

import {
  Button,
  Link,
  Media,
  Modal,
  SingleBlockPortableText,
} from '@pienso/components'
import { CloseIcon } from '@pienso/icons'
import { useState } from 'react'
import { SectionManifestoWithModalProps } from '../SectionManifestoWithModal.model'
import * as s from './ManifestoModal.css'

type ManifestoModalProps = Pick<SectionManifestoWithModalProps, 'cta'>

export const ModalButton = ({ cta }: ManifestoModalProps) => {
  const [isModalOpen, setIsModalOpen] = useState(false)

  if (cta?.ctaLink?.payload?.href) {
    return (
      <Link
        href={cta.ctaLink.payload.href}
        className={s.openModalButton}
        appearance="jumboLarge"
      >
        {cta.ctaLink.payload.label}
      </Link>
    )
  }

  if (!cta?.modalContent) {
    return null
  }

  return (
    <>
      <Button
        onClick={() => setIsModalOpen(true)}
        className={s.openModalButton}
        appearance="jumboLarge"
      >
        {cta?.modalContent?.ctaLabel}
      </Button>
      <Modal
        isOpen={isModalOpen}
        overlayVariant="blur"
        position="center"
        onRequestClose={() => setIsModalOpen(false)}
      >
        <div className={s.innerModalWrap}>
          <div className={s.closeButtonWrap}>
            <Button
              className={s.closeButton}
              onClick={() => setIsModalOpen(false)}
            >
              <CloseIcon />
            </Button>
          </div>
          <div className={s.modalContent}>
            <div className={s.textWrap}>
              {cta?.modalContent?.localeColorText && (
                <SingleBlockPortableText
                  as="p"
                  appearance="body2"
                  weight="medium"
                  withMargin
                  value={cta.modalContent.localeColorText}
                />
              )}
            </div>
            <div className={s.modalImageContainer}>
              {cta?.modalContent?.image?.mediaPayload && (
                <div className={s.modalImageWrap}>
                  <Media
                    mediaPayload={cta?.modalContent?.image?.mediaPayload}
                    mediaPayloadMobile={
                      cta?.modalContent?.image?.mediaPayloadMobile
                    }
                    layout="fill"
                    sizes="(min-width: 740px) 450px, (min-width: 430px) 30vw"
                  />
                </div>
              )}
            </div>
          </div>
        </div>
      </Modal>
    </>
  )
}
