import { convertTime } from '@pienso/utils'
import React from 'react'

type Props = {
  time: string
  clock?: '12' | '24'
  className?: string
}

export const TimeDisplay = ({ time, clock = '12', className }: Props) => {
  const convertedTime = convertTime(time, clock)
  return <span className={className}>{convertedTime}</span>
}
