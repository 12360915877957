'use client'

import { ReactNode, useEffect, useRef, useState } from 'react'
import * as s from './Marquee.css'

interface MarqueeProps {
  children: ReactNode
}

export const Marquee = ({ children }: MarqueeProps) => {
  const ref = useRef<HTMLDivElement>(null)
  const [itemsCount, setItemsCount] = useState(0)

  useEffect(() => {
    const getItemsCount = () => {
      if (!ref.current) return 0

      const width = ref.current.offsetWidth
      const viewportWidth = window.innerWidth
      return width > 0 ? Math.ceil(viewportWidth / width) : 0
    }

    const updateItemsCount = () => {
      setItemsCount(getItemsCount())
    }

    updateItemsCount()

    window.addEventListener('resize', updateItemsCount)
    return () => {
      window.removeEventListener('resize', updateItemsCount)
    }
  }, [])

  return (
    <div className={s.marquee}>
      <span className={s.guide} ref={ref}>
        {children}
      </span>
      {Array.from({ length: itemsCount * 2 }).map((_, idx) => (
        <div className={s.marqueeItem} key={`marqueeItem-${idx}`}>
          {children}
        </div>
      ))}
    </div>
  )
}
