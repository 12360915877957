import "../../../../../../node_modules/@vanilla-extract/webpack-plugin/vanilla.virtual.css?%7B%22fileName%22%3A%22..%2F..%2Flibs%2Fstyles%2Fsrc%2Flib%2Ftheme.css.ts.vanilla.css%22%2C%22source%22%3A%22%23H4sIAAAAAAAAA22USW5jMQxE9zlFAVoH%2BOIfVVnZsXONhqc4cTw7U6fRd29Qq6b4VbsHqUiRlHg9nd7x5w64v%2F%2FVHw%2B7%2FloRIU5UD%2F%2FjSATpVAYLEeq8DK6J0FQqgxvFM5XBLRHapDK4I0KXl8E9EfpaZfBAhKFSGZwUP6oMXhAh5WXwkgiTTmXwigjTWmXwWvGTyuANER7zMviZCLOkMnhLhHmnMviFCE%2BiMviVCJVMp100eJfxpG97g98yTrGxmewVV9M0s96HjCcyHQw%2B5rzn%2Fdze8pQTjENlQ551fGqVwZfxy1%2FVZFAZfFOcl8HvhL32BxFtuz8JseSLKJr8rafspt%2BKWoN%2B1MnuihVRlywSwzDY%2FKMQ1fnbspqQkjVEU7KW6ErWEUPJes25hAMRXZRERBdmQUQXZ0lEF2illSjhmhAXaEOIC%2FRMiPPcaiVL%2BELUzvOVqF2eO6Jxx9%2BIxgXaE63zPBCt8zwSnfM8EZ270ZnoneeFGNzxK5FcoFuev5LqcPsy64T7kn4qdVf90jY7B532xjnowPu6%2FOhMlA5SKS2jSdRRcXuFiKn0FR19l5k0OhjOtyUkub0dUfdub080bohlyC%2FXWaSxoslipOuyJNqyZLIi6qYu6XqsmbIhuljCsYcgW8J1R15G3pG8jsHdyOuQN%2F%2BryN7%2FIHIY%2BQLkOBbmpJ9oSiU%2BE23JLiPBrzptJbzlz%2FRwe7j7%2Bw8BJQpvhwgAAA%3D%3D%22%7D"
import "../../../../../../node_modules/@vanilla-extract/webpack-plugin/vanilla.virtual.css?%7B%22fileName%22%3A%22..%2F..%2Flibs%2Fstyles%2Fsrc%2Flib%2Ftypography.css.ts.vanilla.css%22%2C%22source%22%3A%22%23H4sIAAAAAAAAA63Uz26CMBzA8TtP0aMe2vQPiOJlrwKjQI1UhgSFhXdfZBrtftUU5rX5pp%2B2lJIqU13yRdG3h1B20A0%2BSZUXTYRCSrfe4JHfgMEgMAIOA98IxD3I4lLtuwi1cb3AeBwqZJwqnS%2B3t%2Baoehkhf12dL0N7pSUurvNSshnHZNPIGh%2Br%2BFPpPEKYEipk%2BUCGs0g6jWSBYSbvNANHNJuDCmFBmaO4myNy29dkhD7dJjdMPct03uXjZa1fUskh7f46bPOvvbXTwZUVFNwN7KeDggRW8umdMUX2%2BgWwk1bQcYvs9f9vBdks8KOUqYrRolQan1TaFJelU1qdl%2BMKjBfQ9OgVHO5ZCLNwBarEqcpgFYSg2sHKF6DSsBIcVDWsuA%2Bq1nIUa1D1lgrOxWznasks58roLRt%2BAIdlYAUJBwAA%22%7D"
import "../../../../../../node_modules/@vanilla-extract/webpack-plugin/vanilla.virtual.css?%7B%22fileName%22%3A%22..%2F..%2Flibs%2Fsections%2Fsection-speakers%2Fsrc%2Flib%2Fcomponents%2FSpeakerDetails.css.ts.vanilla.css%22%2C%22source%22%3A%22Ll8xa3R2cGxxMCB7CiAgZGlzcGxheTogZmxleDsKICBmbGV4LWRpcmVjdGlvbjogY29sdW1uOwogIG1heC13aWR0aDogNjAwcHg7CiAgYWxpZ24taXRlbXM6IHN0YXJ0OwogIGp1c3RpZnktY29udGVudDogZW5kOwogIHJvdy1nYXA6IHZhcigtLV83bm1qN3IxZSk7Cn0KLl8xa3R2cGxxMSB7CiAgZGlzcGxheTogbm9uZTsKfQouXzFrdHZwbHEyIHsKICBwYWRkaW5nLWJvdHRvbTogdmFyKC0tXzdubWo3cjFrKTsKICBnYXA6IHZhcigtLV83bm1qN3IxZSk7Cn0KLl8xa3R2cGxxMyB7CiAgY29sb3I6IGJsYWNrOwp9Ci5fMWt0dnBscTQgewogIGRpc3BsYXk6IGZsZXg7CiAgZmxleC1kaXJlY3Rpb246IGNvbHVtbjsKICBnYXA6IHZhcigtLV83bm1qN3IxNCk7Cn0KLl8xa3R2cGxxNSB7CiAgY29sb3I6IHZhcigtLV83bm1qN3I2KTsKfQouXzFrdHZwbHE5IHsKICB3aWR0aDogdmFyKC0tXzdubWo3cjFoKTsKICBoZWlnaHQ6IHZhcigtLV83bm1qN3IxaCk7Cn0KLl8xa3R2cGxxYSB7CiAgcGFkZGluZzogdmFyKC0tXzdubWo3cjE0KTsKICBwYWRkaW5nLWxlZnQ6IDA7CiAgbWFyZ2luLWJvdHRvbTogYXV0bzsKICBhbGlnbi1zZWxmOiBlbmQ7Cn0KQG1lZGlhIChtaW4td2lkdGg6IDEwMDBweCkgewogIC5fMWt0dnBscTAgewogICAgbWF4LXdpZHRoOiA2MDBweDsKICB9CiAgLl8xa3R2cGxxOSB7CiAgICB3aWR0aDogdmFyKC0tXzdubWo3cjFlKTsKICAgIGhlaWdodDogdmFyKC0tXzdubWo3cjFlKTsKICB9CiAgLl8xa3R2cGxxYSB7CiAgICBtYXJnaW4tYm90dG9tOiAwOwogICAgYWxpZ24tc2VsZjogc3RhcnQ7CiAgfQp9%22%7D"
import { createRuntimeFn as _7a468 } from '@vanilla-extract/recipes/createRuntimeFn';
export var closeButton = '_1ktvplqa';
export var speakerInfoPanel = _7a468({defaultClassName:'_1ktvplq0',variantClassNames:{isHidden:{true:'_1ktvplq1'}},defaultVariants:{},compoundVariants:[]});
export var speakerInfoPanelBio = _7a468({defaultClassName:'_1ktvplq6',variantClassNames:{length:{'short':'pfiybqj pfiybq2','long':'pfiybqn pfiybq2'}},defaultVariants:{},compoundVariants:[]});
export var speakerInfoPanelCloseIcon = '_1ktvplq9';
export var speakerInfoPanelContent = '_1ktvplq2';
export var speakerInfoPanelDescription = '_1ktvplq5 pfiybqr';
export var speakerInfoPanelHeadline = '_1ktvplq4 pfiybqr pfiybq1';
export var speakerInfoPanelName = '_1ktvplq3 pfiybqr pfiybq1';