import "../../../../../node_modules/@vanilla-extract/webpack-plugin/vanilla.virtual.css?%7B%22fileName%22%3A%22..%2F..%2Flibs%2Fstyles%2Fsrc%2Flib%2Ftheme.css.ts.vanilla.css%22%2C%22source%22%3A%22%23H4sIAAAAAAAAA22USW5jMQxE9zlFAVoH%2BOIfVVnZsXONhqc4cTw7U6fRd29Qq6b4VbsHqUiRlHg9nd7x5w64v%2F%2FVHw%2B7%2FloRIU5UD%2F%2FjSATpVAYLEeq8DK6J0FQqgxvFM5XBLRHapDK4I0KXl8E9EfpaZfBAhKFSGZwUP6oMXhAh5WXwkgiTTmXwigjTWmXwWvGTyuANER7zMviZCLOkMnhLhHmnMviFCE%2BiMviVCJVMp100eJfxpG97g98yTrGxmewVV9M0s96HjCcyHQw%2B5rzn%2Fdze8pQTjENlQ551fGqVwZfxy1%2FVZFAZfFOcl8HvhL32BxFtuz8JseSLKJr8rafspt%2BKWoN%2B1MnuihVRlywSwzDY%2FKMQ1fnbspqQkjVEU7KW6ErWEUPJes25hAMRXZRERBdmQUQXZ0lEF2illSjhmhAXaEOIC%2FRMiPPcaiVL%2BELUzvOVqF2eO6Jxx9%2BIxgXaE63zPBCt8zwSnfM8EZ270ZnoneeFGNzxK5FcoFuev5LqcPsy64T7kn4qdVf90jY7B532xjnowPu6%2FOhMlA5SKS2jSdRRcXuFiKn0FR19l5k0OhjOtyUkub0dUfdub080bohlyC%2FXWaSxoslipOuyJNqyZLIi6qYu6XqsmbIhuljCsYcgW8J1R15G3pG8jsHdyOuQN%2F%2BryN7%2FIHIY%2BQLkOBbmpJ9oSiU%2BE23JLiPBrzptJbzlz%2FRwe7j7%2Bw8BJQpvhwgAAA%3D%3D%22%7D"
import "../../../../../node_modules/@vanilla-extract/webpack-plugin/vanilla.virtual.css?%7B%22fileName%22%3A%22..%2F..%2Flibs%2Fstyles%2Fsrc%2Flib%2Ftypography.css.ts.vanilla.css%22%2C%22source%22%3A%22%23H4sIAAAAAAAAA63Uz26CMBzA8TtP0aMe2vQPiOJlrwKjQI1UhgSFhXdfZBrtftUU5rX5pp%2B2lJIqU13yRdG3h1B20A0%2BSZUXTYRCSrfe4JHfgMEgMAIOA98IxD3I4lLtuwi1cb3AeBwqZJwqnS%2B3t%2Baoehkhf12dL0N7pSUurvNSshnHZNPIGh%2Br%2BFPpPEKYEipk%2BUCGs0g6jWSBYSbvNANHNJuDCmFBmaO4myNy29dkhD7dJjdMPct03uXjZa1fUskh7f46bPOvvbXTwZUVFNwN7KeDggRW8umdMUX2%2BgWwk1bQcYvs9f9vBdks8KOUqYrRolQan1TaFJelU1qdl%2BMKjBfQ9OgVHO5ZCLNwBarEqcpgFYSg2sHKF6DSsBIcVDWsuA%2Bq1nIUa1D1lgrOxWznasks58roLRt%2BAIdlYAUJBwAA%22%7D"
import "../../../../../node_modules/@vanilla-extract/webpack-plugin/vanilla.virtual.css?%7B%22fileName%22%3A%22..%2F..%2Flibs%2Fsections%2Fsection-callout%2Fsrc%2Flib%2FSectionCallout.css.ts.vanilla.css%22%2C%22source%22%3A%22%23H4sIAAAAAAAAA81UwVKDMBC98xV7cabMmEpAS00v%2FomTktDGAZIJqVSd%2FrsDASUQqt48Mcm%2BffveZpf1M07rd5awCD4CACZqVdA3Agct2C6A7osML1VBDUeZLE5lVRPAuZ5HtWxqAvRk5FecKgKvVK8Qek6r8iXVmIdtoBHMHAngKLppjyXVB1ERiLpse3NGPcjJj7fhLrgE60E29skexeMuTmvFM4M0NUISwA4i8RtXlDFRHZCRUwuxCMeAvTRGllPMiytzc6W7rWWUF7IhoGVztW1DTS0ORzMteXQgBc%2B9iJGo1BWVF%2Fy8UN1StwDEhOaZEbIiYKfBodz%2B8ByPXbyzbbMJ1IpW8OCg6AIqcVD7hZ76mzdKzPyJWjbIk0zDpUnHue7n9RI8lZwJCqtSVOh7uCN1Drtia3fNFvl2NtiqOFU1N%2B35Ms7H4%2FyhOXj3fafbORpfzJdXc8WpWeH4ti0ZWqyN%2BhpgR89REfcq3L0adneqDu4gnVEk%2F8LIQ69iuszxfaTOM%2FRm0DznNqHHeQp3gHoL7l76H3frHY6p3c3Y7e9s7v9OfPX35quR%2BR60W9vYMi7sl5hTsetUl4V9Szz79rhIlfRUn32wW3wCBwAA%22%7D"
import { createRuntimeFn as _7a468 } from '@vanilla-extract/recipes/createRuntimeFn';
export var blockText = '_17szd3dd pfiybqv pfiybq1';
export var contentBlockItem = '_17szd3dc';
export var contentBlocksList = '_17szd3db';
export var contentWrap = _7a468({defaultClassName:'_17szd3d3',variantClassNames:{withBlocks:{true:'_17szd3d4',false:'_17szd3d5'}},defaultVariants:{},compoundVariants:[]});
export var imageContent = '_17szd3d2';
export var link = '_17szd3da';
export var mediaWrap = '_17szd3d1';
export var root = '_17szd3d0';
export var text = '_17szd3d9 pfiybqv pfiybq1';
export var textContent = '_17szd3d6';
export var textWrap = '_17szd3d8';
export var topTextContent = '_17szd3d7';