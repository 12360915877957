'use client'

import { FC, PropsWithChildren } from 'react'
import { button, innerWrapper, wrapper } from './Disclosure.css'
import { DisclosureHeaderDefault } from './DisclosureDefaultHeader/DisclosureHeaderDefault'
import { useDisclosureContext } from './DisclosuresList'

export type DisclosureBodyProps = {
  itemId?: string
  isOpen?: boolean
  className?: string
  innerClassName?: string
}
export const DisclosureBody: FC<PropsWithChildren<DisclosureBodyProps>> = ({
  isOpen,
  itemId,
  children,
  className,
  innerClassName,
}) => {
  return (
    <div
      className={`${wrapper} ${className}`}
      role="region"
      id={`sect${itemId}`}
      aria-labelledby={`accordion-${itemId}`}
    >
      <div className={`${innerWrapper({ isOpen })} ${innerClassName}`}>
        {children}
      </div>
    </div>
  )
}

export type DisclosureItemProps = {
  itemId: string
  className?: string
  label?: string
  renderHeader?: FC<{
    isOpen: boolean
    headingLevel: number
    label?: string
  }>
  headingLevel: 2 | 3 | 4 | 5 | 6
  headerId?: string
  bodyClassName?: string
  innerBodyClassName?: string
  disabled?: boolean
}

export const Disclosure: FC<PropsWithChildren<DisclosureItemProps>> = ({
  itemId,
  label,
  children,
  renderHeader,
  headingLevel,
  headerId,
  bodyClassName,
  innerBodyClassName,
  disabled,
  className,
}) => {
  const { isOpen, setOpen } = useDisclosureContext()
  const handleClick = () => {
    if (itemId !== undefined) {
      setOpen(itemId, !isOpen(itemId))
    }
  }
  return (
    <>
      <button
        className={`${button} ${className}`}
        onClick={handleClick}
        aria-expanded={isOpen(itemId)}
        aria-controls={`sect${itemId}`}
        id={`accordion-${itemId}`}
        aria-disabled={isOpen(itemId)}
        disabled={disabled}
      >
        {renderHeader ? (
          renderHeader({ isOpen: isOpen(itemId), headingLevel, label })
        ) : (
          <DisclosureHeaderDefault
            isOpen={isOpen(itemId)}
            level={headingLevel}
            id={headerId}
          >
            {label || ''}
          </DisclosureHeaderDefault>
        )}
      </button>
      <DisclosureBody
        className={bodyClassName}
        isOpen={isOpen(itemId)}
        itemId={itemId}
        innerClassName={innerBodyClassName}
      >
        {children}
      </DisclosureBody>
    </>
  )
}
