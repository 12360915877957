'use client'

import React, { ComponentPropsWithRef } from 'react'
import { assignInlineVars } from '@vanilla-extract/dynamic'

import {
  ModalContentPosition,
  ModalContentSize,
  ModalContentVariant,
  ModalTransition,
} from './Modal'
import { transitionOpenTime, transitionCloseTime } from './Content.css'

type ContentProps = ComponentPropsWithRef<'div'> & {
  onRequestClose?: (event: React.MouseEvent | React.KeyboardEvent) => void
  position?: ModalContentPosition
  variant?: ModalContentVariant
  size?: ModalContentSize
  transition: ModalTransition
  openTime: number
  closeTime: number
}

export const Content = React.forwardRef<HTMLDivElement, ContentProps>(
  (
    {
      children,
      openTime,
      closeTime,
      transition,
      variant,
      position,
      size,
      onRequestClose,
      className,
      ...restProps
    },
    ref,
  ) => {
    return (
      <div
        className={className}
        {...restProps}
        ref={ref}
        style={assignInlineVars({
          [transitionOpenTime]: `${openTime}ms`,
          [transitionCloseTime]: `${closeTime}ms`,
        })}
      >
        {children}
      </div>
    )
  },
)

Content.displayName = 'Content'
