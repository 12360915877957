import { SectionTheme } from '@pienso/models'
import { theme } from './theme.css'

const DEFAULT_SECTION_BACKGROUND_COLOR = 'white'

const getSectionBackgroundColor = (sectionTheme?: SectionTheme) => {
  const backgroundColor =
    sectionTheme?.backgroundColor || DEFAULT_SECTION_BACKGROUND_COLOR
  switch (backgroundColor) {
    case 'blue':
      return theme.colors.blue
    case 'green':
      return theme.colors.green
    case 'gray':
      return theme.colors.gray75
    case 'lightGray':
      return theme.colors.gray85
    case 'lightestGray':
      return theme.colors.gray95
    case 'white':
      return theme.colors.white
    case 'red':
      return theme.colors.red
    default:
      return undefined
  }
}

const getSectionPrimaryClickableAppearance = (
  sectionTheme?: SectionTheme,
): 'primaryBlue' | 'primaryWhite' => {
  const backgroundColor =
    sectionTheme?.backgroundColor || DEFAULT_SECTION_BACKGROUND_COLOR
  switch (backgroundColor) {
    case 'white':
      return 'primaryBlue'
    case 'blue':
      return 'primaryWhite'
    case 'green':
      return 'primaryWhite'
    case 'gray':
      return 'primaryWhite'
    case 'lightGray':
      return 'primaryBlue'
    case 'lightestGray':
      return 'primaryBlue'
    case 'red':
      return 'primaryWhite'
    default:
      return 'primaryBlue'
  }
}

const getSectionSecondaryClickableAppearance = (
  sectionTheme?: SectionTheme,
): 'secondaryBlue' | 'secondaryBlack' | 'secondaryWhite' => {
  const backgroundColor =
    sectionTheme?.backgroundColor || DEFAULT_SECTION_BACKGROUND_COLOR
  switch (backgroundColor) {
    case 'white':
      return 'secondaryBlue'
    case 'blue':
      return 'secondaryBlack'
    case 'green':
      return 'secondaryBlack'
    case 'gray':
      return 'secondaryBlack'
    case 'lightGray':
      return 'secondaryBlue'
    case 'lightestGray':
      return 'secondaryBlue'
    case 'red':
      return 'secondaryBlack'
    default:
      return 'secondaryBlue'
  }
}

export const getSectionStyles = (sectionTheme?: SectionTheme) => {
  return {
    sectionBackgroundColor: getSectionBackgroundColor(sectionTheme),
    primaryClickableAppearance:
      getSectionPrimaryClickableAppearance(sectionTheme),
    secondaryClickableAppearance:
      getSectionSecondaryClickableAppearance(sectionTheme),
  }
}
