import "../../../../../node_modules/@vanilla-extract/webpack-plugin/vanilla.virtual.css?%7B%22fileName%22%3A%22..%2F..%2Flibs%2Fstyles%2Fsrc%2Flib%2Ftheme.css.ts.vanilla.css%22%2C%22source%22%3A%22%23H4sIAAAAAAAAA22USW5jMQxE9zlFAVoH%2BOIfVVnZsXONhqc4cTw7U6fRd29Qq6b4VbsHqUiRlHg9nd7x5w64v%2F%2FVHw%2B7%2FloRIU5UD%2F%2FjSATpVAYLEeq8DK6J0FQqgxvFM5XBLRHapDK4I0KXl8E9EfpaZfBAhKFSGZwUP6oMXhAh5WXwkgiTTmXwigjTWmXwWvGTyuANER7zMviZCLOkMnhLhHmnMviFCE%2BiMviVCJVMp100eJfxpG97g98yTrGxmewVV9M0s96HjCcyHQw%2B5rzn%2Fdze8pQTjENlQ551fGqVwZfxy1%2FVZFAZfFOcl8HvhL32BxFtuz8JseSLKJr8rafspt%2BKWoN%2B1MnuihVRlywSwzDY%2FKMQ1fnbspqQkjVEU7KW6ErWEUPJes25hAMRXZRERBdmQUQXZ0lEF2illSjhmhAXaEOIC%2FRMiPPcaiVL%2BELUzvOVqF2eO6Jxx9%2BIxgXaE63zPBCt8zwSnfM8EZ270ZnoneeFGNzxK5FcoFuev5LqcPsy64T7kn4qdVf90jY7B532xjnowPu6%2FOhMlA5SKS2jSdRRcXuFiKn0FR19l5k0OhjOtyUkub0dUfdub080bohlyC%2FXWaSxoslipOuyJNqyZLIi6qYu6XqsmbIhuljCsYcgW8J1R15G3pG8jsHdyOuQN%2F%2BryN7%2FIHIY%2BQLkOBbmpJ9oSiU%2BE23JLiPBrzptJbzlz%2FRwe7j7%2Bw8BJQpvhwgAAA%3D%3D%22%7D"
import "../../../../../node_modules/@vanilla-extract/webpack-plugin/vanilla.virtual.css?%7B%22fileName%22%3A%22..%2F..%2Flibs%2Fstyles%2Fsrc%2Flib%2Fgrids.css.ts.vanilla.css%22%2C%22source%22%3A%22Ll8xeDJjd2I5MCB7CiAgZGlzcGxheTogZ3JpZDsKICBncmlkLXRlbXBsYXRlLWNvbHVtbnM6IHJlcGVhdCg0LCAxZnIpOwogIGNvbHVtbi1nYXA6IHZhcigtLV83bm1qN3IxOCk7Cn0KQG1lZGlhIChtaW4td2lkdGg6IDc0MHB4KSB7CiAgLl8xeDJjd2I5MCB7CiAgICBncmlkLXRlbXBsYXRlLWNvbHVtbnM6IHJlcGVhdCgxMiwgMWZyKTsKICAgIGNvbHVtbi1nYXA6IHZhcigtLV83bm1qN3IxZSk7CiAgfQp9%22%7D"
import "../../../../../node_modules/@vanilla-extract/webpack-plugin/vanilla.virtual.css?%7B%22fileName%22%3A%22..%2F..%2Flibs%2Fstyles%2Fsrc%2Flib%2Ftypography.css.ts.vanilla.css%22%2C%22source%22%3A%22%23H4sIAAAAAAAAA63Uz26CMBzA8TtP0aMe2vQPiOJlrwKjQI1UhgSFhXdfZBrtftUU5rX5pp%2B2lJIqU13yRdG3h1B20A0%2BSZUXTYRCSrfe4JHfgMEgMAIOA98IxD3I4lLtuwi1cb3AeBwqZJwqnS%2B3t%2Baoehkhf12dL0N7pSUurvNSshnHZNPIGh%2Br%2BFPpPEKYEipk%2BUCGs0g6jWSBYSbvNANHNJuDCmFBmaO4myNy29dkhD7dJjdMPct03uXjZa1fUskh7f46bPOvvbXTwZUVFNwN7KeDggRW8umdMUX2%2BgWwk1bQcYvs9f9vBdks8KOUqYrRolQan1TaFJelU1qdl%2BMKjBfQ9OgVHO5ZCLNwBarEqcpgFYSg2sHKF6DSsBIcVDWsuA%2Bq1nIUa1D1lgrOxWznasks58roLRt%2BAIdlYAUJBwAA%22%7D"
import "../../../../../node_modules/@vanilla-extract/webpack-plugin/vanilla.virtual.css?%7B%22fileName%22%3A%22..%2F..%2Flibs%2Fsections%2Fsection-speakers%2Fsrc%2Flib%2FSectionSpeakers.css.ts.vanilla.css%22%2C%22source%22%3A%22%23H4sIAAAAAAAAA6VVy5KjIBTd5yvYzFRnQQaTdJIxm%2FmTKRRUEgULMDE1lX%2BfEtQg0kl39coSzr33nPtilW5b0UYI%2FFsAUGGZMw4TobWoYnDB8g3Cv3tenfYyOi%2BPi%2FtiZfFrg88lIzAVZVPxGETH4USKq%2Fkd0VuDLijLCx2DCKEfHZYwVZf4FhsjB%2F1u0DUmhPE8TGZduGR2PfkWXhnRRQy2CNWtA9gbwBiPC06d20NAC%2FgFVI052M1EOX6ykrbdQfeFhEmaaiZ4DKyX7ubUKM2yG0wF15TruHOaUphQfaXUIKS4whzXfrKpqw9bgnNUuhzpaVrVJda0l6BiEGXyi2zDKYg2DpXEUIHQ%2Fv32CO0MnwSn51yKhhtnQnqgwoKEJFRCiQlrlF%2FdavlF5mkjVReoFoxranRribliFugzAmi1UYBiRY%2BPTvOTi21y51k%2FuLVJ4kJcqDRpeSE8c%2B3Sp4kkn0lkbUChG2VufjrUXjqLjMn9QZC6UxhqTwD6aZte2fIOsx66e1p6YSAd76zsJq5ghHSTMvLKDK%2BJhD6Hbnrz6cR%2Ff1JHz8U8%2FqQe%2FQ7Voo4BbrQ4zjfPmLmM6SGgG8GpWyjOxhXKPoSFmuCjtpxU%2FvSZZrbeP2wPgAIbftQdIXS5HqdPwqVwVJ1t84lhgHGiRNlos7X%2FVJQwDN4qxod9v9%2Biul0am8nKDDB4ti6HlXkPR4kQ8sOgPoz3cCLrZYS997CHIKVZer5ZQhMrAHDJcg4VLbMYUE48V4Ow1ypm75a34PeTN84JkfQhhuJsN%2BY1NQKerYPHmy1Dw3%2FqQYGNWiw9CrSnMGR%2BfRgYjB0zHDlWeW9lpjx%2FCHcghd8Xw2a4L%2B7%2FASGrYH4LCQAA%22%7D"
import { createRuntimeFn as _7a468 } from '@vanilla-extract/recipes/createRuntimeFn';
export var contentWrap = 'c4xox13 _1x2cwb90';
export var focusButton = 'c4xox1k';
export var infoStickyContainer = 'c4xox15';
export var infoStickyWrap = 'c4xox14';
export var infoWrap = 'c4xox18';
export var mediaWrap = 'c4xox12';
export var modalContentWrap = 'c4xox1j';
export var speakerButton = _7a468({defaultClassName:'c4xox1h',variantClassNames:{isSelected:{true:'c4xox1i'}},defaultVariants:{},compoundVariants:[]});
export var speakerCard = _7a468({defaultClassName:'c4xox1b',variantClassNames:{selected:{true:'c4xox1c'}},defaultVariants:{},compoundVariants:[]});
export var speakerDescription = 'c4xox1f pfiybqr';
export var speakerHeadline = 'c4xox1g';
export var speakerList = 'c4xox1a';
export var speakerName = 'pfiybqr pfiybq1';
export var speakerThumbnail = 'c4xox1e';
export var text = 'pfiybqv';
export var textWrap = _7a468({defaultClassName:'c4xox16',variantClassNames:{isHidden:{true:'c4xox17'}},defaultVariants:{},compoundVariants:[]});
export var title = 'c4xox10 pfiybqf pfiybq1';