'use client'

import debounce from 'lodash/debounce'
import throttle from 'lodash/throttle'
import * as s from './SectionMarquee.css'
import { SectionMarqueeProps } from './SectionMarquee.model'
import { getSectionStyles } from '@pienso/styles'
import { SectionContainer } from '@pienso/components'
import { Marquee } from './Marquee'
import { useEffect, useRef, useState } from 'react'

export const SectionMarquee = ({ text, sectionTheme }: SectionMarqueeProps) => {
  const { sectionBackgroundColor } = getSectionStyles(sectionTheme)
  const [mounted, setMounted] = useState(false)
  const viewportWidthRef = useRef<number>(0)

  useEffect(() => {
    // Resetting with debounce to prevent multiple renders when resizing the window
    const mount = debounce(() => {
      setMounted(true)
    }, 100)

    const resetMount = () => {
      if (window.innerWidth === viewportWidthRef.current) return
      setMounted(false)
      viewportWidthRef.current = window.innerWidth
      mount()
    }
    const throttledResetMount = throttle(resetMount, 100)

    setMounted(true)
    window.addEventListener('resize', throttledResetMount)
    return () => {
      window.removeEventListener('resize', throttledResetMount)
    }
  }, [])

  return (
    <SectionContainer
      backgroundColor={sectionBackgroundColor}
      appearance="WITH_BACKGROUND"
      overflowHidden
    >
      <div className={s.wrap}>
        {mounted && (
          <Marquee>
            <p className={s.marquee}>{text}</p>
          </Marquee>
        )}
      </div>
    </SectionContainer>
  )
}
