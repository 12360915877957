'use client'

import { EventSpeaker } from '@pienso/models'
import * as s from './SpeakerDetails.css'
import { Button } from '@pienso/components'
import { CloseIcon } from '@pienso/icons'
type Props = {
  speaker: EventSpeaker | null
  handleSetInfo: (speaker: EventSpeaker | null) => void
  isVisible: boolean
}

export const SpeakerDetails = ({
  speaker,
  handleSetInfo,
  isVisible,
}: Props) => {
  if (!speaker || !isVisible) return null
  return (
    <div
      className={s.speakerInfoPanel({
        isHidden: !isVisible,
      })}
    >
      <Button
        appearance="empty"
        aria-label={`Close ${speaker?.name}`}
        onClick={() => handleSetInfo(null)}
        tabIndex={isVisible ? 0 : -1}
        className={s.closeButton}
      >
        <CloseIcon className={s.speakerInfoPanelCloseIcon} />
      </Button>
      <div className={s.speakerInfoPanelHeadline}>
        <p className={s.speakerInfoPanelName}>
          <span>{speaker?.name}</span>
          {speaker?.title && <span>, {speaker?.title}</span>}
        </p>
        <p className={s.speakerInfoPanelDescription}>{speaker?.description}</p>
      </div>
      {speaker?.bio && (
        <div
          className={s.speakerInfoPanelBio({
            length: speaker?.bio?.length > 250 ? 'long' : 'short',
          })}
        >
          {speaker?.bio}
        </div>
      )}
    </div>
  )
}
